import EntityCta from "./EntityCta"
import Panel from "../../../layout/Panel"
import { observer } from "mobx-react-lite"
import { store } from "../../../../stores/Store"
import PanelBody from "../../../layout/PanelBody"
import PanelLabel from "../../../layout/PanelLabel"
import PanelHeader from "../../../layout/PanelHeader"
import EntityDetailSection from "./EntityDetailSection"
import { flattenNode } from "../../../../misc/nodeFlattener"
import { DetailPanelProps } from "./DetailPanel"


export default observer(function SponsorDetail ({index}: DetailPanelProps) {

    var node = store.NodeStore.leafNode
    var flattenedNode = flattenNode(node)

    return (
        <Panel index={index} id={node?.uid} className="detail" width="100%" loadingContent={store.NodeStore.loadingLeafNode}>

            <PanelLabel title="Person details" />

            <PanelHeader node={node} closeable onClose={() => store.NodeStore.setLeafNode(null)}>
                <EntityCta entity={node} />
            </PanelHeader>

            <PanelBody>
                <div className="panel-columns">
                    <div className="panel-column">
                        {flattenedNode.sections.filter((s, i) => (i < (flattenedNode.sections.length/2)) || s.uid.includes("ATTRS")) // Iterate every other section and always show attrs
                            .map((section: any, section_index: number) => <EntityDetailSection entityUid={node.uid} key={section_index} section={section} />)}
                    </div>
                    <div className="panel-column">
                        {flattenedNode.sections.filter((s, i) => !(i < (flattenedNode.sections.length/2)) && !s.uid.includes("ATTRS")) // Iterate every other section
                            .map((section: any, section_index: number) => <EntityDetailSection entityUid={node.uid} key={section_index} section={section} />)}
                    </div>
                </div>
            </PanelBody>

        </Panel>
    )
})