import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { DisplayValue } from "../../../..";
import { Icons } from "../../../../misc/Icons";
import { store } from "../../../../stores/Store";


export default observer(function EnquiryDetailSection () {

    var {enquiry} = store.EnquiryStore

    return (
        <div>
            <div className="section-header"><h5>Details</h5></div>
            
            <div className="detail-section">
                
                {/* <EnquiryStatusIndicator enquiry={enquiry} /> */}
                <div className="section-header section-subheader">
                    <h5>Basic details</h5>
                </div>

                <div className="section-table">
                    {enquiry.subjects.map((subject, i) => (
                        <div key={i} className="section-row attribute-row">
                            <div className="section-cell">Subject</div>
                            <div className="section-cell link" onClick={() => store.NodeStore.navigateNode(subject.uid)}>{subject.name}</div>
                        </div>
                    ))}

                    <div className="section-row attribute-row">
                        <div className="section-cell">Legal system</div>
                        <div className="section-cell">{DisplayValue("legal_system", enquiry.ruleSystem)}</div>
                    </div>

                    <div className="section-row attribute-row">
                        <div className="section-cell">Interest date</div>
                        <div className="section-cell">
                            {enquiry.interestDate === "8888-12-31T00:00:00+00:00" ? "Current date" : format(new Date(enquiry.interestDate), "dd-MM-yyyy")}
                        </div>
                    </div>

                    <div className="section-row attribute-row">
                        <div className="section-cell">Created at</div>
                        <div className="section-cell">
                            {format(new Date(enquiry.created), "dd-MM-yyyy H:mm")}
                        </div>
                    </div>

                    <div className="section-row attribute-row">
                        <div className="section-cell">Last run</div>
                        <div className="section-cell">
                            {format(new Date(enquiry.lastStarted), "dd-MM-yyyy H:mm")}
                        </div>
                    </div>

                    <div className="section-row attribute-row">
                        <div className="section-cell">Last Interim Outcome</div>
                        <div className="section-cell">
                            {enquiry.lastInterimOutcomeDt ? format(new Date(enquiry.lastInterimOutcomeDt), "dd-MM-yyyy H:mm") : null}
                        </div>
                    </div>

                    <div className="section-row attribute-row">
                        <div className="section-cell">Last Definitive Outcome</div>
                        <div className="section-cell">
                            {enquiry.lastDefinitiveOutcomeDt ? format(new Date(enquiry.lastDefinitiveOutcomeDt), "dd-MM-yyyy H:mm") : null}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
})