
import {
    IconButton,
    Button
} from '@mui/material'
import Logo from '../../misc/Logo';
import { Link } from 'react-router-dom';
import { Icons } from '../../misc/Icons';
import { store } from '../../stores/Store';
import { AlertModal } from '../..';
import TicketRequestForm from '../form/TicketRequestForm';
import Survey from '../../misc/Survey';


export default function MainMenu({handleClose}) {

    return (
        <div className="main-menu">
            <div className="main-menu-header">
                <div className="header">
                    <Button component={Link} to="/app/main" className="btn btn-lowercase">
                        <div>
                            <Logo type="block" height="20px" color="#c0c0c0" />
                            <small>{store.AppStore.sessionInfo.parentAccount.name}</small>
                        </div>
                    </Button>
                    <IconButton size="small" onClick={() => handleClose()}>
                        <Icons.X />
                    </IconButton>
                </div>
            </div>

            <div className="main-menu-body">
                <nav className="main-menu-nav">
                    {!store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false) ? (
                        <>
                            <Button className="main-menu-link" onClick={() => store.ToolStore.tool = "account"}>
                                <Icons.Account fontSize="small" />
                                <span>Account Management</span>
                            </Button>

                            {store.AppStore.checkJwtRole("account_manager") ? (
                                <Button className="main-menu-link" onClick={() => store.ToolStore.tool = "report"}>
                                    <Icons.Report fontSize="small" />
                                    <span>Report Management</span>
                                </Button>
                            ) : null}

                            <Button className="main-menu-link" onClick={() => store.ToolStore.tool = "profile"}>
                                <Icons.User fontSize="small" />
                                <span>My Profile</span>
                            </Button>

                            {/* {store.AppStore.checkJwtRole("account_manager") ? (
                                <Button className="main-menu-link" component={Link} to="/app/report-management">
                                    <Icons.Report fontSize="small" />
                                    <span>Report Management</span>
                                </Button>
                            ) : null} */}

                            <a href="https://file.aoralaw.com/images/Aora ND User Guide WEB v1.1.pdf" target="_blank" rel="noreferrer">
                                <Button className="main-menu-link">
                                    <Icons.Open fontSize="small" />
                                    <span>User Guide</span>
                                </Button>
                            </a>
                            
                            <a href="https://file.aoralaw.com/images/AORA SWV Getting Started.pdf" target="_blank" rel="noreferrer">
                                <Button className="main-menu-link">
                                    <Icons.Open fontSize="small" />
                                    <span>Getting Started: SWV</span>
                                </Button>
                            </a>

                            <a href="https://file.aoralaw.com/images/Aora ND Getting Started.pdf" target="_blank" rel="noreferrer">
                                <Button className="main-menu-link">
                                    <Icons.Open fontSize="small" />
                                    <span>Getting Started: Nationality</span>
                                </Button>
                            </a>

                            <a href="https://www.aoralaw.com/added-value-swv.php" target="_blank" rel="noreferrer">
                                <Button className="main-menu-link">
                                    <Icons.Open fontSize="small" />
                                    <span>Resources: SWV</span>
                                </Button>
                            </a>

                            <a href="https://www.aoralaw.com/added-value.php" target="_blank" rel="noreferrer">
                                <Button className="main-menu-link">
                                    <Icons.Open fontSize="small" />
                                    <span>Resources: Nationality</span>
                                </Button>
                            </a>

                            <Button className="main-menu-link" onClick={() => AlertModal({body: <TicketRequestForm />})} >
                                <Icons.Bug fontSize="small" />
                                <span>Report a problem</span>
                            </Button>

                            {process.env.NODE_ENV === "development" ? (
                                <Button className="main-menu-link" component={Link} to="/app/ui-demo">
                                    <Icons.Component fontSize="small" />
                                    <span>UI Testing</span>
                                </Button>
                            ) : null}
                        </>
                    ) : null}

                    <Button className="main-menu-link" onClick={() => {store.AppStore.logout()}}>
                        <Icons.Exit fontSize="small" />
                        <span>Logout</span>
                    </Button>
                </nav>
            </div>
        </div>
    )
}