const Survey = {
    EndOfFirstEnquiry: () => {
        console.log("Asking survey")
        window._sva.showSurvey('b89d0fe69c6e4b9b', {forceDisplay: true});
    },
    EndOfEnquiry: () => {
        console.log("Asking survey")
        window._sva.showSurvey('2673e9871df0b6d6', {forceDisplay: true});
    }
}

export default Survey;