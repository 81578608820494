import { Slider } from "@mui/material"
import { InputFieldProps } from "../../../models/Interfaces";
import { SelectOption } from "./SelectField"


interface SliderSelectProps extends InputFieldProps {
    value?: string;
    className?: string;
    options: SelectOption[];
}


export default function SliderSelectField ({
    name,
    value, 
    label,
    options, 
    onChange, 
    warning,
    helpText,
    formik = false,
    disabled = false,
    className = "",
    id = Math.random().toString()
}: SliderSelectProps) {

    var marks = []

    // Populate 'marks' array with the position of each option by a value of 0 to 100
    // This is so the snapping effect aligns with the labels
    for (var i = 0; i < options.length; i++)
    {
        let position = (i / (options.length - 1)) * 100

        marks.push({
            value: position, 
            outputValue: options[i].value
        })
    }

    const valueToPosition = (value) => {

        var position = 0

        options.forEach((option: any, o: number) => {
            if (option.value === value) {
                position = marks[o].value
            } 
        })

        return position
    }

    const handleChange = (event: Event, value: number, activeThumb: number) => {
        
        var selectedOption = marks.find((mark) => mark.value === value)

        if (selectedOption) {
            var onChangeArg: any = {
                target: {
                    type: "select",
                    name: name,
                    value: selectedOption.outputValue
                }
            }
            
            if (!formik) {
                onChangeArg = onChangeArg.target
            }
    
            onChange(onChangeArg)
        }        
    }

    return (
        <div className="form-field field-slider">
            
            {label ? <label className="field-slider-label" htmlFor={id}>{label}</label> : null}

            <Slider
                id={id}
                name={name}
                step={null}
                size="small"
                marks={marks}
                aria-valuetext={value}
                disabled={disabled}
                className={className}
                valueLabelDisplay="off"
                onChange={handleChange}
                value={valueToPosition(value)}
                aria-label={label ? label : "Slider select"}
            />
            
            <div className="slider-labels">
                {options.map((option, i) => <span key={i} className={option.value === value ? "slider-label selected" : "slider-label"}>{option.label.toLowerCase()}</span>)}
            </div>

            {warning !== false ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}

        </div>
        
    )
}