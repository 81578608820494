import React from "react"
import { history } from '../../../../index'
import Ajax from "../../../../Ajax"
import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import ReportSection from "./ReportSection"
import { store } from "../../../../stores/Store"
import NextStepsSection from "./NextStepsSection"
import { Enquiry } from "../../../../models/Enquiry"
import EnquiryDetailSection from "./EnquiryDetailSection"
import EnquiryStatusSection from "./EnquiryStatusSection"
import EnquiryStatusIndicator from "./EnquiryStatusIndicator"
import EnquirySettingsSection from "./EnquirySettingsSection"
import Panel from "../../../layout/Panel"
import PanelLabel from "../../../layout/PanelLabel"
import PanelHeader from "../../../layout/PanelHeader"
import PanelBody from "../../../layout/PanelBody"
import { DetailPanelProps } from "./DetailPanel"
import { AlertModal } from "../../../.."
import EnquiryOutcomeModal from "../../../modals/EnquiryOutcomeModal"


export default observer(function EnquiryDetail ({index}: DetailPanelProps) {

    const {enquiry} = store.EnquiryStore

    if (enquiry && store.AppStore.getURLPeram(window.location.href, "action") === "outcome") {
        AlertModal({id: "outcome", body: <EnquiryOutcomeModal enquiry={enquiry} />, size: "lg"})
        history.push(window.location.pathname)
    }

    return (
        <Panel index={index} id={enquiry?.uid} className="detail" width="100%" loadingContent={!enquiry}>

            <PanelLabel title="Enquiry details" />

            <PanelHeader node={enquiry} closeable onClose={() => store.NodeStore.setLeafNode(null)}>
                <EnquiryCta enquiry={enquiry} />
                <EnquiryStatusIndicator enquiry={enquiry} />
            </PanelHeader>

            <PanelBody>
                <div className="panel-columns enquiry-detail">
                    <div>
                        <EnquiryDetailSection />
                        <EnquiryStatusSection enquiry={enquiry} />
                        <EnquirySettingsSection />
                    </div>

                    <div>
                        <ReportSection />
                        <NextStepsSection />
                    </div>
                </div>
            </PanelBody>

        </Panel>
    )
})

interface EnquiryCtaProps {
    enquiry: Enquiry;
}

const EnquiryCta = observer(({enquiry}: EnquiryCtaProps) => {

    const [loading, setLoading] = React.useState(false)

    const submit = () => {
        setLoading(true)
        store.EnquiryStore.progressEnquiry().finally(() => setLoading(false))
    }

    if (store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false) && enquiry.status === "DEFINITIVE") return <></>

    if (store.AppStore.stateSubject === enquiry.uid) return <Button onClick={() => Ajax.Message.Cancel()} variant="contained" className="btn btn-xs enquiry-cta">Cancel</Button>

    if (store.AppStore.sessionState) return <></>

    return (
        <Button onClick={() => submit()} disabled={loading || !!store.AppStore.sessionState} variant="contained" className="btn btn-xs enquiry-cta">
            {store.EnquiryStore.actionLabel(enquiry)}
        </Button>
    )
})