import React from "react"
import { observer } from "mobx-react-lite"
import { IconButton } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import PanelBody from "../../layout/PanelBody"
import AccountSelect from "../main/AccountSelect"
import PanelHeader from "../../layout/PanelHeader"
import AccountMgmtDetails from "./AccountMgmtDetails"
import AccountMgmtChildren from "./AccountMgmtChildren"


export default observer(function AccountMgmtTool() {    

    const {account, getAccount, setTool} = store.ToolStore
    
    React.useEffect(() => {
        if (!account) {
            getAccount()
        }
    }, [])
    
    return (
        <div className="panel panel-dark">
            <PanelHeader>
                <div className="header">
                    <h2>Account management</h2>
                    <AccountSelect value={account?.uid} accounts={store.NodeStore.accounts} handleSelect={(uid) => getAccount(uid)} />
                </div>
                <IconButton style={{marginLeft: "auto"}} size="small" onClick={() => setTool(null)}><Icons.X /></IconButton>
            </PanelHeader>
            <PanelBody>
                <AccountMgmtDetails />
                <AccountMgmtChildren />
            </PanelBody>
        </div>
    )
})