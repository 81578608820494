import { useState } from "react"
import TestForm from "../../form/TestForm"
import Field from "../../form/fields/Field"
import { DisplayDictionary } from "../../.."
import { FormFieldOutput } from "../../../models/Interfaces"
import ModalBase from "../../modals/ModalBase"
import { Button } from "@mui/material"


export default function DemoForms () {

    const [text, setText] = useState("")
    const [checked, setChecked] = useState(false)
    const [date, setDate] = useState("12/11/1996")
    const [select, seetSelect] = useState("")
    const [files, setFiles] = useState<File[]>([])

    const [multipleSelect, setMultipleSelect] = useState([])
    const [checkboxSelect, setCheckboxSelect] = useState([])

    const setSelect = (value) => {
        console.log("Select", value)
        seetSelect(value)
    }

    return (
        <div className="settings-section form-demo">

            <TestForm />

            <div className="form">
                <div className="form-fields">
                    <Field.Checkbox
                        name="checkbox"
                        value={checked}
                        label="Check mate"
                        onChange={(fieldOutput: FormFieldOutput) => setChecked(!checked)} />

                    <Field.Switch
                        name="checkbox"
                        value={checked}
                        label="Switch mate"
                        onChange={(fieldOutput: FormFieldOutput) => setChecked(!checked)} />

                    <Field.Text
                        name="text"
                        value={text}
                        placeholder="Text field"
                        onChange={(fieldOutput: FormFieldOutput) => setText(fieldOutput.value)} />

                    <Field.Text
                        name="text"
                        type="number"
                        value={text}
                        placeholder="Number field"
                        onChange={(fieldOutput: FormFieldOutput) => setText(fieldOutput.value)} />

                    <Field.Date
                        name="date"
                        value={date}
                        onChange={(fieldOutput: FormFieldOutput) => setDate(fieldOutput.value)} />

                    <Field.Date
                        name="date"
                        value={date}
                        openDateCheckbox={{label: "Christmas day", value: "2022/12/25"}}
                        onChange={(fieldOutput: FormFieldOutput) => setDate(fieldOutput.value)} />

                    <Field.Select
                        name="select"
                        value={select}
                        placeholder="Select something"
                        onChange={(fieldOutput: FormFieldOutput) => setSelect(fieldOutput.value)}
                        options={[
                            {value: 1, label: "Option 1"},
                            {value: 2, label: "Option 2"}
                        ]}/>
                    <Field.MultipleSelect
                        name="select"
                        value={multipleSelect}
                        placeholder="Select some things"
                        onChange={(fieldOutput: FormFieldOutput) => setMultipleSelect(fieldOutput.value)}
                        options={[
                            {value: 1, label: "Option 1"},
                            {value: 2, label: "Option 2"}
                        ]}/>
                </div>
            </div>


            <div>
                <Field.LargeListSelect
                    name="select"
                    value={multipleSelect}
                    label="Large list select"
                    disabled={true}
                    noneOption={true}
                    onChange={(fieldOutput: FormFieldOutput) => setMultipleSelect(fieldOutput.value)}
                    options={DisplayDictionary("countries", true)}
                />

                <Field.MinimisedLargeListSelect
                    name="select"
                    value={multipleSelect}
                    label="Large list select"
                    disabled={true}
                    onChange={(fieldOutput: FormFieldOutput) => setMultipleSelect(fieldOutput.value)}
                    options={DisplayDictionary("countries", true)}
                />

                <br/>

                <Field.File
                    label="Select a file"
                    multiple
                    value={files}
                    onChange={(fieldOutput: FormFieldOutput) => {
                        console.log(fieldOutput, files)
                        setFiles(fieldOutput.value)
                    }}
                    />
            </div>

            <div>
                <Field.Radio
                    name="select"
                    value={select}
                    label="All we hear is"
                    placeholder="Select something"
                    onChange={(fieldOutput: FormFieldOutput) => setSelect(fieldOutput.value)}
                    options={[
                        {value: "Radio Ga Ga", label: "Radio Ga Ga"},
                        {value: "Radio Goo Goo", label: "Radio Goo Goo"}
                    ]}/>

                <Field.SliderSelect
                    name="select"
                    value={select}
                    onChange={(fieldOutput: FormFieldOutput) => setSelect(fieldOutput.value)}
                    options={[
                        {value: "Radio Ga Ga", label: "Radio Ga Ga"},
                        {value: "Radio Goo Goo", label: "Radio Goo Goo"}
                    ]}/>

                <br/>

                <Field.CheckboxSelect
                    label="Checkbox select"
                    value={checkboxSelect}
                    onChange={(fieldOutput: FormFieldOutput) => setCheckboxSelect(fieldOutput.value)}
                    options={[
                        {label: "Option1", value: "1"},
                        {label: "Option2", value: "2"},
                        {label: "Option3", value: "3", children: [
                            {label: "Option3.1", value: "3.1"},
                            {label: "Option3.2", value: "3.2"},
                            {label: "Option3.3", value: "3.3", children: [
                                {label: "Option3.3.1", value: "3.31"},
                                {label: "Option3.3.2", value: "3.32"},
                                {label: "Option3.3.3", value: "3.33"},
                            ]},
                        ]},
                    ]} />

            </div>
        </div>
    )
}
