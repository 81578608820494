import React from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import PanelBody from "../../layout/PanelBody"
import PanelHeader from "../../layout/PanelHeader"
import { AlertModal, DisplayValue } from "../../.."
import { UserUpdateForm } from "../../form/admin/UserForms"
import { Button, CircularProgress, IconButton } from "@mui/material"
import UpdatePasswordForm from "../../form/admin/UserPasswordUpdateForm"


export default observer(function ProfileMgmtTool() {

    const {user, getUser, setTool} = store.ToolStore
    
    React.useEffect(() => {
        if (!user) {
            getUser()
        }
    }, [])

    return (
        <div className="panel panel-dark">
            <PanelHeader>
                <h2>My Profile</h2>
                <IconButton style={{marginLeft: "auto"}} size="small" onClick={() => setTool(null)}><Icons.X /></IconButton>
            </PanelHeader>
            <PanelBody>
                <UserDetail />
            </PanelBody>
        </div>
    )
})

const UserDetail = observer(() => {

    const {user, getUser, loading} = store.ToolStore

    if (!user || loading) return <CircularProgress color="info" />

    return (
        <div className="tool-section">
            <div className="tool-table">
                <ToolDetailRow label="First name" value={user.firstName} />
                <ToolDetailRow label="Family name" value={user.familyName} />
                <ToolDetailRow label="Title" value={DisplayValue("title", user.title)} />
                <ToolDetailRow label="Username" value={user.username} />
                <ToolDetailRow label="Email" value={user.email} />
                <ToolDetailRow label="Phone" value={user.phone} />
                <ToolDetailRow label="Unique ID" value={user.uid} />
                <ToolDetailRow label="Organisation" value={<Link className="link" to={`/app/main/${user.account.uid}`} >{user.account.name}</Link>} />
                <ToolDetailRow label="Is account manager" value={user.isAccountManager ? "Yes" : "No"} />
                <ToolDetailRow label="Active" value={user.active ? "Yes" : "No"} />
                <ToolDetailRow label="Open enquiries" value={user.openEnquiries} />
                <ToolDetailRow label="Closed enquiries" value={user.closedEnquiries} />
                <ToolDetailRow label="Reports ordered" value={user.reportsRequested} />
            </div>
            
            <div className="button-group">
                <Button 
                    color="success" 
                    variant="contained" 
                    className="btn btn-sm" 
                    component={Link} to={`/app/main/${user.uid.replace("USER", "FOLDER")}`}>
                    Go to workspace
                </Button>
                {store.AppStore.checkJwtRole("account_manager") ? (
                    <Button 
                        className="btn btn-sm" 
                        variant="contained" 
                        color="info" 
                        onClick={() => AlertModal({title: "Edit user", body: <UserUpdateForm user={user} onSuccess={getUser} />})}>
                        Edit user
                    </Button>
                ) : null}
                <Button 
                    className="btn btn-sm" 
                    variant="contained" 
                    color="info" 
                    onClick={() => AlertModal({title: "Update password", body: <UpdatePasswordForm user={user} />, size: "xs"})}>
                    Update password
                </Button>
            </div>
        </div>
    )
})

export function ToolDetailRow ({label, value, hidden = false}) {

    // The server won't provide these fields to non-account managers
    var hiddenFields = ["Title", "Username", "Email", "Phone", "Is account manager", "Active"]

    if (hidden || !store.AppStore.checkJwtRole("account_manager") && hiddenFields.includes(label)) {
        return <></>
    }

    return (
        <div style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
            <p><b>{label}</b></p>
            <p>{value}</p>
        </div>
    )
}