import { history } from '.'
import { useEffect } from 'react'
import { store } from './stores/Store'
import { observer } from 'mobx-react-lite'
import {LinearProgress} from '@mui/material'
import Header from './components/layout/Header'
import { Route, Switch } from 'react-router-dom'
import ErrorBoundary from './misc/ErrorBoundary'
import NotFound from './components/errors/NotFound'
import PrivateRoute from './components/PrivateRoute'
import ErrorView from './components/errors/ErrorView'
import MainView from './components/views/main/MainView'
import GuestView from './components/views/guest/GuestView'
import UiDemoView from './components/views/ui_demo/UiDemoView'


export default observer(function App() {

	const {AppStore} = store

	useEffect(() => {

		// This is where we re-authenticate after a page refresh
		const loadAuthentication = async () => {

			// This page-refresh-authentication is dependent on either a
			// pre-existing token in session storage or auth_code in URI

			// Attempt to get user with existing token
			if (AppStore.token) {

				await AppStore.init().then(() => {
					// Redirect to main view if not already on an authorized path
					if (!history.location.pathname.includes("/app")) {
						history.push("/app/main" + history.location.search)
					}

					AppStore.setAppLoaded()
					return
				}).catch(async () => {
					await AppStore.logout()
				})
			}

			// Attempt to sign in user via OAuth using 'code' parameter in URI
			var oauthTokenRequestBody = {
				code: AppStore.getURLPeram(window.location.href, "code"),
				state: AppStore.getURLPeram(window.location.href, "state"),
				redirect_uri: process.env.REACT_APP_ROOT_URL
			}

			if (oauthTokenRequestBody.code) {
				await AppStore.loginOAuth(oauthTokenRequestBody)
			}

			if (AppStore.isLoggedIn) return

			// Default to login screen
			history.push("/" + history.location.search)
			AppStore.setAppLoaded()
		}

		loadAuthentication()

	}, [AppStore])

	if (!AppStore.appLoaded) return <LinearProgress />

	return (
		<div className="App">
			<Switch>

				<Route path="/" exact component={GuestView} />
				<Route path="/error/:error" component={ErrorView} />

				<Route path={'/app(.*)'} render={() => {

					if (!store.ConnectionStore.hubConnection) {
						store.ConnectionStore.createHubConnection()
					}

					return (
						<ErrorBoundary>

							<Header/>
							<Switch>
								<PrivateRoute path="/app/main" exact component={MainView} />
								<PrivateRoute path="/app/main/:folder_uid" exact component={MainView} />
								<PrivateRoute path="/app/main/:folder_uid/:leafnode_uid" exact component={MainView} />
								
								<PrivateRoute path="/app/ui-demo" component={UiDemoView} />
								<Route component={NotFound} />
							</Switch>

						</ErrorBoundary>
					)
				}}/>

				<Route path={'/(.*)'} component={NotFound} />

			</Switch>
		</div>
	)
})
