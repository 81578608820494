import Ajax from '../Ajax'
import { store } from './Store'
import { Theme } from '../misc/Theme'
import { Alert, AlertModal } from '..'
import { makeAutoObservable } from 'mobx'
import { Enquiry } from '../models/Enquiry';
import ReportRequestForm from '../components/form/report/ReportRequestForm';


export class EnquiryStore {

    constructor() {
        makeAutoObservable(this)
    }

    get enquiry () {

        if (store.NodeStore.leafNode?.typeName === "Aora.Platform.Data.EnquiryNode"){
            return store.NodeStore.leafNode
        }

        return null
    }

    get enquiryStatuses () {

        var statuses = this.enquiry?.childrenObjects?.find(s => s.name === "STATUSES")
        
        if (statuses) {
            return statuses.childrenObjects
        }

        return null
    }

    get getQualifyingRule () {

        var statuses = this.enquiry?.childrenObjects?.find(s => s.name === "STATUSES")
        if (statuses) {
            var positiveStatus = statuses.childrenObjects.find(status => status.value === "Y")
            if (positiveStatus) {
                return positiveStatus.qualifyingRule
            }
        }

        return null
    }

    rerun = () => {
        Ajax.Enquiry.Rerun(this.enquiry.uid)
    }

    setEnquiry (enquiry: Enquiry) {
        
        store.NodeStore.setLeafNode(enquiry)
        
        if (enquiry) {
            var rSection = enquiry.childrenObjects.find(section => section.name === "REPORTS")
            if (rSection) {
                store.ReportStore.setReports(rSection.childrenObjects)
            }
        }
    }

    color (enquiry: Enquiry): string {
        
        var value = Theme.palette.info.contrastText
        
        if (enquiry?.status === "DEFINITIVE" || enquiry?.status === "FINAL") {
            value = Theme.palette.success.main
        }
        if (enquiry?.status === "RERUN_REQUIRED" || enquiry?.status === "REPORT_DATA_REQUIRED") {
            value = Theme.palette.error.main
        }
        
        return value
    }

    actionLabel (enquiry: Enquiry): string {
        
        var value = "Run enquiry"
        
        if (!enquiry?.valid) {
            value = "Rerun"
        } else if (enquiry?.status === "DEFINITIVE") {
            value = "Order reports"
        } else if (enquiry?.status === "INTERIM") {
            value = "Confirm assumptions"
        } else if (enquiry?.status === "REPORT_DATA_REQUIRED") {
            value = "Complete now"
        } else if (enquiry?.status === "INITIAL") {
            value = "Resume"
        }
        
        return value
    }

    subjectName (enquiry: Enquiry) {
        
        if (enquiry?.subjects?.length) {
            return enquiry.subjects[0].name            
        }
        
        return "No subject"
    }

    progressEnquiry = async () => {

        if (!this.enquiry) {
          
            return Promise.reject("No enquiry")
        
        } else if (this.enquiry.status === "DEFINITIVE" && this.enquiry.valid) {
            
            store.AlertStore.Close()

            store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false)
                ? AlertModal({body: <h4>You are not authorised to order reports</h4>, width: "350px"})    
                : AlertModal({body: <ReportRequestForm enquiry={this.enquiry} />, width: "500px"})

        } else if (!this.enquiry.valid) {
            return Ajax.Enquiry.Rerun(this.enquiry.uid).catch((response) => Alert({message: response.data.detail}))
        } else {
            return Ajax.Enquiry.Run(this.enquiry.uid).catch((response) => Alert({message: response.data.detail}))
        }
        
        return Promise.resolve()
    }

}