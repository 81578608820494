import React from "react";
import { format } from "date-fns"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material"
import { InputFieldProps } from "../../../models/Interfaces";
import Field from "./Field";


interface OpenDateCheckbox {
    value: string;
    label: string;
}


interface DateFieldProps extends InputFieldProps {
    value?: string | Date;
    minDate?: Date;
    maxDate?: Date;
    openDateCheckbox?: OpenDateCheckbox;
    hideOpener?: boolean;
}


export default function DateField ({
    name = "",
    value = undefined,
    label = "",
    onChange,
    warning = "",
    helpText = "",
    formik = false,
    disabled = false,
    autoFocus = false,
    hideOpener = false,
    minDate = new Date("1800-01-01"),
    maxDate = new Date(),
    openDateCheckbox = undefined
}: DateFieldProps) {

    const [secondaryCheckbox, setSecondaryCheckbox] = React.useState(false)
    const [revertValue, setRevertValue] = React.useState(null)

    return (
        <div className="form-field field-date">

            <div style={{display: "flex", alignItems: "baseline"}}>
                <DatePicker
                    openTo="year"
                    label={label}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled={secondaryCheckbox || disabled}
                    disableOpenPicker={hideOpener}
                    aria-label="Date field"
                    inputFormat="dd/MM/yyyy"
                    aria-describedby="day, month, year"
                    value={value ? value : null}
                    renderInput={(params) => <TextField {...params} autoFocus={autoFocus} /> }
                    onChange={(dateObject: Date) => {
                        
                        if (formik) {
                            onChange({target: {name: name, value: dateObject ? dateObject : null}})
                        } else {
                            onChange({
                                name: name, 
                                value: dateObject === null || dateObject.toString() === "Invalid Date" 
                                    ? null
                                    : format(dateObject, "yyyy-MM-dd")
                            })
                        }
                    
                    }}
                />
                
                {openDateCheckbox ? (

                    // Secondary checkboxes are for when the value does not yet exist e.g. the person hasn't died yet.
                    // When checked it should set the value to secondaryCheckboxValues[0] and then disabled the 
                    // primary input. When unchecked it should revert to the value of the primary input.

                    <Field.Checkbox
                        formik
                        name="asdf"
                        label={openDateCheckbox.label}
                        value={secondaryCheckbox}
                        onChange={(e) => {
                            
                            var event = { target: {
                                name: name, 
                                value: openDateCheckbox.value
                            }}
                            
                            if (e.target.checked) {
                                setRevertValue(value)
                                setSecondaryCheckbox(true)
                            } else {
                                setSecondaryCheckbox(false)
                                event.target.value = revertValue ? format(new Date(revertValue), "yyyy-MM-dd") : null
                            }
                            
                            formik ? onChange(event) : onChange(event.target)
                        }} /> 

                ) : null}
                
            </div>

            {warning !== false ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}

        </div>
    )
}