import React from 'react'
import Ajax from '../../Ajax'
import { AlertModal } from '../..'
import { Icons } from '../../misc/Icons'
import Field from '../form/fields/Field'
import { observer } from 'mobx-react-lite'
import { store } from '../../stores/Store'
import { Button, CircularProgress } from '@mui/material'


export default observer(function SystemInformationModal () {
    return (
        <Button
            className="btn btn-xs btn-lowercase"
            style={{color: "#c9c9c9", marginLeft: "auto"}}
            onClick={() => AlertModal({body: <SystemInformation />, width: "450px"})}>
            {store.ConnectionStore.connectionState ? store.ConnectionStore.connectionState : "Disconnected"}
        </Button>
    )
})


export const SystemInformation = observer(function () {

    const {ConnectionStore} = store
    const {stopHubConnection, createHubConnection, connectionState, hubConnection, testConnection, testCount, transportTypes, setTransportType, testsVerified, testComplete, transportType} = ConnectionStore
    const [serverInfo, setServerInfo] = React.useState(null)
    const tokenClaims = store.AppStore.decodeJWT()

    const getServerInfo = () =>
        Ajax.Session.SessionDebug().then((response) => setServerInfo(response.data))

    React.useEffect(() => {
        getServerInfo()
    }, [])

    if (!serverInfo) return <h4>Loading...</h4>

    return (
        <div>
            <h4>Connection information</h4>
            <p>Connection state: {connectionState ? connectionState : "Disconnected"}</p>
            <Field.Select warning={false} label="Select transport type" formik onChange={(e) => setTransportType(e.target.value)} value={transportType} options={Object.keys(transportTypes).map(key => {return {value: key, label: transportTypes[key]}})} />
            <p>
                The AORA app works by listening for messages from the server.
                You are currently {connectionState === "Connected" ? "connected and listening" : "disconnected and not listening"}.
            </p>
                
            <Field.Switch formik label="Listening" value={!!connectionState} warning={false} onChange={(e) => {
                if (e.target.checked) createHubConnection()
                else stopHubConnection()
            }} />

            <p>To make sure you are able to receive messages, you can run a connection test.</p>
            <Button
                className="btn btn-xs"
                disabled={!testComplete}
                onClick={() => testConnection()} >
                {testComplete 
                    ? <><Icons.Refresh />Run connection test</> 
                    : <CircularProgress style={{width: "10px", height: "10px", margin: "4px"}} />}
            </Button>
            <p>{testsVerified} out of {testCount} test messages arrived successfully.</p>
            <br/>
            
            <h4>Session information</h4>
            <p>Pending question: {serverInfo.pending_question ? serverInfo.pending_question : ""}</p>
            <p>Pending popup: {serverInfo.pending_popup ? serverInfo.pending_popup : ""}</p>
            <p>Pending edit: {serverInfo.pending_edit ? "Yes" : ""}</p>
            <p style={{display: "flex", gap: "10px"}}>
                Session state: {serverInfo.your_session_state ? serverInfo.your_session_state : ""}
                {serverInfo.your_session_state ? (
                    <Button className="btn btn-xs" onClick={() => Ajax.Message.Cancel().then(() => getServerInfo())}>
                        Reset state
                    </Button>
                ) : null}
            </p>
            
            <br/>

            <h4>System information</h4>
            <InfoTableSection title="Info from server" object={serverInfo} />
            <InfoTableSection title="Info from JWT" object={tokenClaims} />
            <InfoTableSection title="Info from hub connection" object={hubConnection} />
        </div>
    )
})


function InfoTableSection ({title, object}) {

    const [minimised, setMinimised] = React.useState(true)

    return (
        <div style={{margin: "10px 0"}}>
            <Button className="btn btn-xs" onClick={() => setMinimised(!minimised)}>
                <Icons.ChevronRight style={{transform: minimised ? "rotate(0deg)" : "rotate(90deg)" }} />
                {title}
            </Button>

            {!minimised && !object ? <p>No information available</p> : null}

            {!minimised && !!object ? (
                <table style={{width: "100%", whiteSpace: "pre"}}>
                    <tbody>
                        {Object.keys(object).map((key, i) => (
                            <tr key={i}>
                                <td>{key}</td>
                                <td>{JSON.stringify(object[key], null, "\t")}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null}
        </div>
    )
}