// import { permissions } from "../../../models/Permissions";


export default function DemoPermissions () {
    return (
        <div className="settings-section">
            <h4>Permissions</h4>

            {/* {permissions.map((permission, index) => (
                <div style={{display: "grid", gridTemplateColumns: "300px 90px 200px", padding: "2px 0"}}>
                    <div>{permission[0]}</div>
                    <div>{permission[1]}</div>
                    <div>{permission[2](null) ? "Yes" : "No"}</div>
                </div>
            ))} */}
        </div>
    )
}