import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { DisplayDictionary } from "../../.."
import { store } from "../../../stores/Store"

// I separated the form into two components so that I could insert this form to be a section of another form.

export function UserCreateForm ({account, onSuccess = () => {}}) {
    return <UserFormOnly
        includeUsername={true}
        includePassword={true}
        requireCredentials={true}
        handleCancel={() => store.AlertStore.Close()}
        handleSubmit={async (values) => {
            await Ajax.Node.Create("user", account.uid, values).then((response) => {
                onSuccess()
            })
        }} />
}

export function UserUpdateForm ({user, onSuccess = () => {}, requestFullUser = false}) {

    const [fullUser, setFullUser] = React.useState(null)

    React.useEffect(() => {
        if (requestFullUser) {
            Ajax.Node.Get(user.uid).then((response) => {
                setFullUser(response.data)
            })
        } else {
            setFullUser(user)
        }
    }, [])

    if (fullUser === null) return <p>Loading form...</p>

    return <UserFormOnly
        initialValues={fullUser}
        requireCredentials={true}
        includeUsername={!!fullUser.username}
        handleCancel={() => store.AlertStore.Close()}
        handleSubmit={async (values) => {
            await Ajax.Node.Edit("user", values).then((response) => {
                onSuccess()
            })
        }} />
}

export default function UserFormOnly ({
    handleSubmit,
    handleCancel,
    guidance = null,
    initialValues = null,
    hidden = false,
    includePassword = false,
    includeUsername = true,
    requireCredentials = true
}) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)
    const [showCredentials, setShowCredentials] = React.useState(requireCredentials)
    const showUsername = includeUsername && showCredentials
    const showPassword = includePassword && showCredentials

    var defaultInitialValues = {
        firstName: "",
        familyName: "",
        sex: "M",
        title: "",
        email: "",
        phone: "",
        username: "",
        password: "",
        isAccountManager: false,
        active: true
    }

    // Allow some or all default values to be overridden
    if (initialValues) {
        initialValues = {...defaultInitialValues, ...initialValues}
    } else {
        initialValues = defaultInitialValues
    }

    var formik = useFormik({

        initialValues: initialValues,

        validationSchema: Yup.object({
            firstName: Yup.string().required("This field is required"),
            familyName: Yup.string().required("This field is required"),
            title: Yup.string().required("This field is required"),
            email: Yup.string().matches(
                /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/,
                "A valid email is required"
            ).required("This field is required"),
            // phone: Yup.string().matches(
            //     /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
            //     "A valid phone number is required"
            // ).required("This field is required"),
            username: showUsername ? Yup.string().required() : Yup.string().nullable(),
            // password: showPassword ? Yup.string().required() : Yup.string().nullable(),
        }),

        onSubmit: async (values) => {

            setError("")
            setSubmitting(true)

            return await handleSubmit(values).then(() => {

                store.NodeStore.refreshFolderNode()
                store.AlertStore.Close()

            }).catch((response) => {

                setError(response.data?.detail ? response.data?.detail : "Something went wrong!")
                response.errors && formik.setErrors(response.errors)

            }).finally(() => setSubmitting(false))

        }
    })

    if (hidden) return null

    return (
        <form autoComplete="off" className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>
            
            {guidance ? <small>{guidance}</small> : null}

            <div className="form-fields" style={{maxHeight: "60vh"}}>
                <Field.Text
                    formik
                    label="First name"
                    name="firstName"
                    value={formik.values.firstName}
                    warning={formik.submitCount && formik.errors.firstName ? formik.errors.firstName : false}
                    onChange={formik.handleChange} autoFocus={true} />

                <Field.Text
                    formik
                    label="Last name"
                    name="familyName"
                    value={formik.values.familyName}
                    warning={formik.submitCount && formik.errors.familyName ? formik.errors.familyName : false}
                    onChange={formik.handleChange}  />

                <Field.Select
                    formik
                    label="Title"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange} 
                    warning={formik.submitCount && formik.errors.title ? formik.errors.title : false}
                    options={DisplayDictionary("title", true).filter(option => !["*", "OTH"].includes(option.value))}  />

                <Field.Text
                    formik
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    warning={formik.submitCount && formik.errors.email ? formik.errors.email : false}
                    onChange={formik.handleChange}  />

                {initialValues.uid ? (
                    <Field.Text
                        formik
                        label="Phone"
                        name="phone"
                        value={formik.values.phone}
                        warning={formik.submitCount && formik.errors.phone ? formik.errors.phone : false}
                        onChange={formik.handleChange}  />
                ) : null}
                    
                {initialValues.uid ? (
                    <Field.Checkbox
                        formik
                        label="Active"
                        name="active"
                        value={formik.values.active}
                        warning={formik.submitCount && formik.errors.active ? formik.errors.active : false}
                        onChange={formik.handleChange} />
                ) : null}

                {!initialValues.uid && !requireCredentials ? (
                    <Field.Checkbox
                        formik
                        label="User is able to login"
                        value={showCredentials}
                        onChange={() => {
                            setShowCredentials(!showCredentials)
                            if (!showCredentials) {
                                formik.setFieldValue("username", "")
                                formik.setFieldValue("password", "")
                            }
                        }} />
                ) : null}

                {showCredentials && <Field.Checkbox
                    formik
                    label="Account manager"
                    name="isAccountManager"
                    value={formik.values.isAccountManager}
                    warning={formik.submitCount && formik.errors.isAccountManager ? formik.errors.isAccountManager : false}
                    onChange={formik.handleChange} />}

                {showUsername ? (
                    <Field.Text
                        formik
                        label="Username"
                        name="newUsername"
                        autoComplete={false}
                        value={formik.values.username}
                        warning={formik.submitCount && formik.errors.username ? formik.errors.username : false}
                        onChange={(e) => {
                            formik.setFieldValue("username", e.target.value)
                        }} />
                ) : null}

                {showPassword ? (
                    <Field.Text
                        formik
                        label="Password"
                        name="newPassword"
                        type="password"
                        autoComplete={false}
                        value={formik.values.password}
                        warning={formik.submitCount && formik.errors.password ? formik.errors.password : false}
                        onChange={(e) => {
                            formik.setFieldValue("password", e.target.value)
                        }} />
                ) : null}
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => handleCancel()}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    type="submit">
                    Submit
                </Button>
            </div>

        </form>
    )
}