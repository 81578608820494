import * as Yup from 'yup'
import { Alert } from '../..'
import { useFormik } from "formik"
import Field from "./fields/Field"
import { useStore } from "../../stores/Store"
import { Button, CircularProgress } from "@mui/material"


export default function LoginForm () {

    const {AppStore} = useStore()

    var codeChallenge = AppStore.getCookie("AORA-OAuth-PKCE-code-challenge")
    var oauthProvider = AppStore.getCookie("AORA-OAuth-provider")
    var clientId = AppStore.getCookie("AORA-OAuth-client-id")
    var tenantId = AppStore.getCookie("AORA-OAuth-tenant-id")
    var redirectUri = AppStore.getCookie("AORA-OAuth-redirect-uri")
    redirectUri = decodeURI(redirectUri)
    
    const formik = useFormik({

        initialValues: {
            username: "",
            password: ""
        },

        validationSchema: Yup.object({
            username: Yup.string().required("Please give your username"),
            password: Yup.string().required("Please give your password")
        }),

        onSubmit: async (values, actions) => {
            
            return await AppStore.login(values).catch((response) => {
                
                var errors: any = {}
                
                if (response?.data?.errors) {
 
                    Object.keys(response?.data?.errors).forEach(key => {
                        errors[key] = String(response.data?.errors[key])
                    })

                } else if (response?.data?.detail) {
                    errors.password = response.data?.detail
                } else {
                    errors.password = "Something went wrong"
                }

                actions.setErrors(errors)
            })
        }
    })

    if (oauthProvider === "microsoft" && clientId && tenantId) {
        return (
            <div style={{width: "300px"}}>
                <h2>Sign in</h2>
                <p>Your account is configured to connect using Microsoft, click the button bellow to sign in.</p>
                <br/>
                <Button
                    className="btn btn-md"
                    color="info"
                    variant="contained"
                    style={{textTransform: "none"}}
                    href={`https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?` +
                        `client_id=${clientId}&` +
                        `state=${oauthProvider}&` +
                        `redirect_uri=${redirectUri}&` +
                        "code_challenge=" + codeChallenge + "&" +
                        "scope=https%3A%2F%2Fgraph.microsoft.com%2Fopenid&" +
                        "response_type=code&" +
                        "code_challenge_method=S256"
                    }>
                    Sign in with 
                    <img alt="M" height="14" src="/images/microsoft.png" />
                </Button>
            </div>
        )
    }

    return (
        <form autoComplete="on" className="form form-login" onSubmit={formik.handleSubmit}>

            <h2>Sign in</h2>

            <div className="form-fields">
                <Field.Text
                    formik
                    name="username"
                    label="Username"
                    autoFocus={true}
                    autoComplete={true}
                    warning={formik.submitCount ? formik.errors.username : null}
                    value={formik.values.username}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    type="password"
                    name="password"
                    label="Password"
                    autoComplete={true}
                    warning={formik.submitCount ? formik.errors.password : null}
                    value={formik.values.password}
                    onChange={formik.handleChange} />
            </div>

            <div className="form-footer">
                <Button className="btn-md" disabled={formik.isSubmitting} type="submit" color="primary" variant="contained">
                    {!formik.isSubmitting ? "Sign in" : <CircularProgress />}
                </Button>
            </div>
        </form>
    )
}


function OAuthButton () {
    
    const {AppStore} = useStore()

    var codeChallenge = AppStore.getCookie("AORA-OAuth-PKCE-code-challenge")
    var oauthProvider = AppStore.getCookie("AORA-OAuth-provider")
    var clientId = AppStore.getCookie("AORA-OAuth-client-id")
    var tenantId = AppStore.getCookie("AORA-OAuth-tenant-id")
    var redirectUri = AppStore.getCookie("AORA-OAuth-redirect-uri")
    redirectUri = decodeURI(redirectUri)

    

    return <></>
}