import React from "react"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Tab, Tabs } from "@mui/material"
import { FormSectionProps } from "../../../models/Interfaces"


export default function EnquirySettingsForm ({formId, submit, section}: FormSectionProps) {

    const [tab, setTab] = React.useState("basic")

    var initialSettingsValues = {}

    section.childrenObjects.forEach(setting => {
        if (setting.datatype === "B") {
            initialSettingsValues[setting.code] = setting.value === "true"    
        } else {
            initialSettingsValues[setting.code] = setting.value !== null
                ? setting.value
                : setting.availableValues[0].value
        }
    })

    const formik = useFormik({
        initialValues: initialSettingsValues,

        onSubmit: (values) => {
            var newSection = {...section}
            newSection.childrenObjects = section.childrenObjects?.map(setting => {
                if (setting.code in values) {
                    setting.value = values[setting.code].toString()
                }
                return setting
            })
            submit(newSection)
        }
    })

    var basicSettings = section.childrenObjects.filter(setting => setting.category === "BASIC")
    var reportSettings = section.childrenObjects.filter(setting => setting.category === "REPORT")
    var advancedSettings = section.childrenObjects.filter(setting => setting.category === "ADVANCED")

    const SettingField = ({setting}) => {

        if (setting.datatype === "B") {
            return (
                <Field.Checkbox
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={!!formik.values[setting.code]}
                    warning={false}
                    onChange={formik.handleChange} />
            )
        }
        if (setting.datatype === "I") {
            return (
                <Field.SliderSelect
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={formik.values[setting.code]}
                    warning={false}
                    options={setting.availableValues.map(option => {
                        return {
                            value: option.value,
                            label: option.label === null ? option.value : option.label
                        }
                    })}
                    onChange={(e) => formik.handleChange(e)} />
            )
        }
        if (setting.datatype === "S") {
            return (
                <Field.Select
                    formik
                    name={setting.code}
                    label={setting.label}
                    value={formik.values[setting.code]}
                    warning={false}
                    options={setting.availableValues.map(option => {
                        return {
                            value: option.value,
                            label: option.label === null ? option.value : option.label
                        }
                    })}
                    onChange={(e) => formik.handleChange(e)} />
            )
        }
        return <h5>{setting.label}</h5>
    }

    return (
        <form id={formId} className="form-fields" onSubmit={formik.handleSubmit}>
            <Tabs
                value={tab}
                // centered={true}
                textColor="primary"
                indicatorColor="primary"
                >
                <Tab onClick={() => setTab("basic")} value="basic" label="Basic" />
                <Tab onClick={() => setTab("report")} value="report" label="Report" />
                <Tab onClick={() => setTab("advanced")} value="advanced" label="Advanced" />
            </Tabs>

            <div style={{display: "grid", gridGap: "4px", marginTop: "15px"}}>
                {tab === "basic" && basicSettings.map((setting, i) => <SettingField key={i} setting={setting} />)}
                {tab === "advanced" && advancedSettings.map((setting, i) => <SettingField key={i} setting={setting} />)}
                {tab === "report" && reportSettings.map((setting, i) => <SettingField key={i} setting={setting} /> )}
            </div>
        </form>
    )
}