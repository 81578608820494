import { Button, Container } from '@mui/material'
import { Link } from 'react-router-dom'

export default function NotFound() {
    return (
        <div className="view">
            {/* <GuestBackground /> */}
            <Container maxWidth="xl" className="container">
                <div className="card card-sm center-h">
                    <div className="card-header">
                        <h2>Not found</h2>
                    </div>
                    <div className="card-content">                
                        Sorry we couldn't find what you are looking for
                    </div>
                    <div className="card-footer">
                        <Button variant="contained" className="btn btn-sm" component={Link} to="/app/main">
                            Return to App
                        </Button>
                    </div>
                </div>    
            </Container>        
        </div>
    )
}