import { store } from "../stores/Store";

var {checkJwtRole, checkJwtRoles} = store.AppStore

export const permissions:
    [type: string,                                      action: string,         expression: Function][] = [     // return true if allowed, false if not allowed
    ["Aora.Platform.Data.AccountNode",                  "CREATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Platform.Data.AccountNode",                  "READ",                 (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Platform.Data.AccountNode",                  "UPDATE",               (node: any) => { return         checkJwtRole("account_manager") && !checkJwtRole("restricted_account")                          }],
    ["Aora.Platform.Data.AccountNode",                  "DELETE",               (node: any) => { return         node.uid !== store.AppStore.sessionInfo.parentAccount.uid && (checkJwtRole("sysuser") || (checkJwtRole("account_manager") && ["client", "sponsor"].includes(node.accountType)))  }],

    ["Aora.Nationality.Data.SponsorAccountNode",        "CREATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Nationality.Data.SponsorAccountNode",        "READ",                 (node: any) => { return         true                                                                                            }],
    ["Aora.Nationality.Data.SponsorAccountNode",        "UPDATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Nationality.Data.SponsorAccountNode",        "DELETE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],

    ["Aora.Platform.Data.UserNode",                     "CREATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Platform.Data.UserNode",                     "READ",                 (node: any) => { return         true                                                                                            }],
    ["Aora.Platform.Data.UserNode",                     "UPDATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Platform.Data.UserNode",                     "DELETE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],

    ["Aora.Nationality.Data.SponsorNode",               "CREATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Nationality.Data.SponsorNode",               "READ",                 (node: any) => { return         true                                                                                            }],
    ["Aora.Nationality.Data.SponsorNode",               "UPDATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Nationality.Data.SponsorNode",               "DELETE",               (node: any) => { return         false                                                                                           }],

    ["Aora.Platform.Data.CaseNode",                     "CREATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Platform.Data.CaseNode",                     "READ",                 (node: any) => { return         true                                                                                            }],
    ["Aora.Platform.Data.CaseNode",                     "UPDATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Platform.Data.CaseNode",                     "DELETE",               (node: any) => { return         checkJwtRole("account_manager") || node.owner.uid === store.AppStore.sessionInfo.userUid        }],

    ["Aora.Nationality.Data.PersonNode",                "CREATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Nationality.Data.PersonNode",                "READ",                 (node: any) => { return         true                                                                                            }],
    ["Aora.Nationality.Data.PersonNode",                "UPDATE",               (node: any) => { return         true                                                                                            }],
    ["Aora.Nationality.Data.PersonNode",                "DELETE",               (node: any) => { return         false                                                                                           }],

    ["Aora.Platform.Data.EnquiryNode",                  "CREATE",               (node: any) => { return         !checkJwtRoles(["client_account", "sponsor_account"], false)                                                             }],
    ["Aora.Platform.Data.EnquiryNode",                  "READ",                 (node: any) => { return         true                                                                                            }],
    ["Aora.Platform.Data.EnquiryNode",                  "UPDATE",               (node: any) => { return         !checkJwtRoles(["client_account", "sponsor_account"], false)                                                             }],
    ["Aora.Platform.Data.EnquiryNode",                  "DELETE",               (node: any) => { return         false                                                                                           }],

    ["Aora.Platform.Data.ReportNode",                   "CREATE",               (node: any) => { return         checkJwtRole("account_manager")                                                                 }],
    ["Aora.Platform.Data.ReportNode",                   "READ",                 (node: any) => { return         true                                                                                            }],
    ["Aora.Platform.Data.ReportNode",                   "UPDATE",               (node: any) => { return         checkJwtRole("sysuser")                                                                         }],
    ["Aora.Platform.Data.ReportNode",                   "DELETE",               (node: any) => { return         false                                                                                           }],
]

export default function IsAllowed(nodeOrTypeName: any, action: "CREATE"|"READ"|"UPDATE"|"DELETE"): boolean {

    var typeName = typeof nodeOrTypeName === "string" ? nodeOrTypeName : nodeOrTypeName.typeName

    const permission = permissions.find(row => (row[0] === typeName || row[0].includes(`.${typeName}`)) && row[1] === action);

    if (!permission) {
        return true
    }

    return permission[2](nodeOrTypeName);
}
