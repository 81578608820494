import { Redirect, Route } from "react-router-dom";
import { useStore } from "../stores/Store";

// interface PrivateRouteProps extends RouteProps {
//     exact?: boolean;
//     path?: string;
//     render?: any;
//     component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
// }

export default function PrivateRoute({component: Component, ...rest}) {
    
    const {AppStore: {isLoggedIn}} = useStore()

    return (
        <Route 
            {...rest}
            render={(props: any) => isLoggedIn ? <Component {...props} /> : <Redirect to="/" />}
        />
    )
}