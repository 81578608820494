import {Column, Table, AutoSizer} from 'react-virtualized';
import NodeOptionsMenu from '../../../misc/NodeMenus';
import { Button, CircularProgress, LinearProgress } from "@mui/material"
import { store } from '../../../stores/Store';
import { observer } from 'mobx-react-lite';
import { DisplayValue } from '../../..';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Theme } from '../../../misc/Theme';


export default observer(function ReportMgmtTable () {

    const { loading, reports, filterReports } = store.ReportStore
    const filteredReports = filterReports(reports)
    // const { loading, reports } = store.ToolStore

    return (
        <div style={{height: "100%", width: "100%", overflowY: "hidden", overflowX: "scroll"}}>
            <div className="table-report-management" style={{minWidth: "900px", overflowY: "hidden", textAlign: "center"}}>
                <AutoSizer>
                    {({height, width}) => (
                        <Table
                            width={width}
                            height={height}
                            headerHeight={44}
                            rowHeight={30}
                            rowCount={filteredReports.length}
                            rowGetter={({index}) => filteredReports[index]}
                        >
                            <Column width={120} dataKey="" headerRenderer={() => <ColumnHeader value="subject" />}         cellRenderer={(data) => <Link className='link' to={`/app/main/${data.rowData.case.uid}/${data.rowData.subject.uid}`} >{data.rowData.subject.name}</Link>} />
                            <Column width={70}  dataKey="" headerRenderer={() => <ColumnHeader value="case" />}            cellRenderer={(data) => <Link className='link' to={`/app/main/${data.rowData.case.uid}`} >{data.rowData.case.name}</Link>} />
                            {/* <Column width={100} dataKey="" headerRenderer={() => <ColumnHeader value="account" />}         cellRenderer={(data) => <Link className='link' to={`/app/main/${data.rowData.account.uid}`} >{data.rowData.account.name}</Link>} /> */}
                            <Column width={90}  dataKey="" headerRenderer={() => <ColumnHeader value="requestedBy" />}     cellRenderer={(data) => data.rowData.requestedBy.name} />
                            <Column width={120} dataKey="" headerRenderer={() => <ColumnHeader value="reportType" />}      cellRenderer={(data) => DisplayValue("report_type", data.rowData.reportType)} />
                            <Column width={100} dataKey="" headerRenderer={() => <ColumnHeader value="legalSystem" />}     cellRenderer={(data) => DisplayValue("legal_system", data.rowData.legalSystem)} />
                            <Column width={100} dataKey="" headerRenderer={() => <ColumnHeader value="statusId" />}        cellRenderer={(data) => DisplayValue("nationality", data.rowData.statusId)} />
                            <Column width={80}  dataKey="" headerRenderer={() => <ColumnHeader value="fileStatus" />}      cellRenderer={(data) => DisplayValue("report_file_status", data.rowData.fileStatus)} />
                            {/* <Column width={50}  dataKey="" headerRenderer={() => <ColumnHeader label="Notes" value="userReference" />}         dataKey="userReference" /> */}
                            <Column width={70}  dataKey="" headerRenderer={() => <ColumnHeader value="requestedAt" />}     cellRenderer={(data) => format(new Date(data.rowData.requestedAt), "yyyy-MM-dd")} />
                            <Column width={40} dataKey="reportLanguageId" headerRenderer={() => <ColumnHeader value="reportLanguageId" />} />
                            <Column width={45} dataKey="version" headerRenderer={() => <ColumnHeader value="version" />} />
                            <Column width={50}  dataKey="" headerRenderer={() => <ColumnHeader value="valid" />}           cellRenderer={(data) => <ReportValidity report={data.rowData} />} />
                            <Column width={35}  dataKey="uid"headerRenderer={() => <></>}                                  cellRenderer={(data) => <NodeOptionsMenu node={data.rowData} />} />

                        </Table>
                    )}
                </AutoSizer>

                {loading ? <CircularProgress color="info" style={{margin: "150px auto"}} /> : null}
            
            </div>
        </div>
    )
})

function ReportValidity ({report}) {

    var color = report.valid ? Theme.palette.success.main : Theme.palette.error.main
    var label = report.valid ? "Valid" : "Invalid"

    return <span style={{color: color, backgroundColor: "white", padding: "1px 4px", borderRadius: "5px", fontSize: "10px", fontWeight: "600"}}>{label}</span>
}

function ColumnHeader ({value}) {
    return (
        <Button className="btn btn-lowercase" color="info" onClick={() => {
            store.ReportStore.orderedBy = value
        }}>
            <b>{DisplayValue("label", value)}</b>
        </Button>
    )
}