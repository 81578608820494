
import Field from "./fields/Field"
import { store } from "../../stores/Store"
import { observer } from "mobx-react-lite"
import { AlertModal, DisplayValue } from "../.."
import { Button, CircularProgress } from '@mui/material'
import InformationRequestField from './InformationRequestField'


export default observer(function InformationRequestForm () {

    const {informationRequest, loading} = store.QuestionStore

    if (loading || !informationRequest) {
        return (
            <div>
                <div style={{width: "fit-content", display: "flex"}}>
                    <CircularProgress style={{ width: "10px", height: "10px", marginRight: "8px" }} />
                    <p style={{margin: 0}}>Waiting for server </p>
                </div>
                <br/>
                <small style={{color: "#d8d8d8"}}>{store.QuestionStore.provisionCount} provisions evaluated</small>
            </div>
        )
    }

    return <InfomationRequestFormContent informationRequest={informationRequest}  />

})

function InfomationRequestFormContent ({informationRequest}) {

    var formik = informationRequest.generateFormik()

    const showQuestionDetail = () => {
        var body = JSON.stringify(informationRequest, null, "\t") + "\n\nFORMIK:\n" + JSON.stringify(formik, null, "\t")
        AlertModal({body: body, size: "xl", code: true})
    }

    var navigateToSubject = () => {
        var subjectUid = informationRequest.locatorPath?.nodeRefs?.at(0)?.uid
        
        if (subjectUid) {
            store.NodeStore.navigateNode(subjectUid)
        }
    }

    // Because the only multi-field question puts its general help under the first field,
    // collect any help texts and display them beneath all fields
    var helpText = []

    return (
        <form autoComplete="off" className="from form-information-request" onSubmit={formik.handleSubmit}>

            <div className="question-details">

                <small className="title" onClick={() => navigateToSubject()}>
                    {informationRequest.title}
                </small>

                {informationRequest.statement
                    ? <span className="help-text">{informationRequest.statement}</span>
                    : null}

                <h4 className="question-text">{informationRequest.mainText}</h4>

            </div>

            {informationRequest.suffix
                ? <small className="suffix">{informationRequest.suffix}</small>
                : null}

            {informationRequest.requestedFieldsObj?.map((field, f) => { // Field loop

                var certaintyValue = informationRequest.globalCertainty
                    ? formik.values["certainty"]
                    : formik.values[`${f}-certainty`]

                var certaintyIndex = field.allowedCertainties.indexOf(formik.values[informationRequest.globalCertainty ? "certainty" : `${f}-certainty`])
                var certaintyMessage = field.certaintyMessages[certaintyIndex]
                
                field.help && helpText.push(field.help)

                return (
                    <div key={f}>
                        <InformationRequestField field={field} formik={formik} certainty={certaintyValue} index={f} key={f} />

                        {/* {field.help ? <HelpText help={field.help} /> : null} */}

                        {!informationRequest.globalCertainty && field.certaintiesRequired && field.allowedCertainties?.length > 1
                            ? <Field.Radio
                                formik
                                horizontal
                                options={field.allowedCertainties.map(value => {return {value: value, label: DisplayValue("answer_certainty", value)}})}
                                name={`${f}-certainty`}
                                value={formik.values[`${f}-certainty`]}
                                helpText={formik.errors[`${f}-certainty`]}
                                disabled={formik.isSubmitting}
                                warning={false}
                                onChange={(e) => {
                                    e.target.value === "UNKNOWN" && informationRequest.setAssumedValues(f)
                                    formik.handleChange(e)
                                }} />
                            : null}

                        <div className="help-text">{certaintyMessage}</div>
                    </div>
                )
            })}

            {helpText.map((text, i) => <HelpText key={i} help={text} />)}

            {informationRequest.globalCertainty && informationRequest.allowedCertainties?.length > 1
                ? <Field.Radio
                    formik
                    label="This information is..."
                    horizontal
                    options={informationRequest.allowedCertainties.map(value => {return {value: value, label: DisplayValue("answer_certainty", value)}})}
                    name="certainty"
                    value={formik.values.certainty}
                    helpText={formik.errors.certainty}
                    disabled={formik.isSubmitting}
                    warning={false}
                    onChange={(e) => {
                        e.target.value === "UNKNOWN" && informationRequest.setAssumedValues()
                        formik.handleChange(e)
                    }} />
                : null}

            {informationRequest?.errorText 
                ? <p className="warning">{informationRequest?.errorText}</p>
                : null}
            
            {store.QuestionStore.serverValidationMessage
                ? <p className="warning">{store.QuestionStore.serverValidationMessage?.substring(0, 120)}</p>
                : null}

            {informationRequest.title !== "Absence" ? (
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn-sm submit-button"
                    disabled={formik.isSubmitting} >
                    {!formik.isSubmitting ? "Submit" : <CircularProgress color="info" />}
                </Button>
            ) : null}

        </form>
    )
}


function HelpText ({help}) {
    
    return help.style === "URL" 
        ? <a className="help-text link" target="_default" href={help.details}>Read more about {help.label}</a>
        : <p className="help-text">{help?.details}</p>
}


