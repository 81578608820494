import React from "react"


export default function TruncText ({text, style = {}}) {
    
    const [toolTip, setToolTip] = React.useState("")
    const [toolTipAnchor, setToolTipAnchor] = React.useState({x: 0, y: 0})
    const ref = React.useRef<HTMLElement>()

    const revealTruncatedText = (e) => {
        
        var fullWidth = ref.current.offsetWidth
        var targetRect = e.target.getBoundingClientRect()

        // console.log("Reveal", e.target.offsetWidth < fullWidth, e.target.offsetWidth, fullWidth, ref)

        if (e.target.offsetWidth < fullWidth) {
            setToolTip(text)
            setToolTipAnchor({x: targetRect.left + 10, y: targetRect.bottom})
        }
    }

    const hideTruncatedText = (e) => {
        
        setToolTip("")
        setToolTipAnchor({x: -1000, y: -1000})
    }

    return (
        <>
            <span
                className="trunc-revealer" 
                style={{
                    display: toolTip ? "block" : "none",
                    left: toolTipAnchor.x,
                    top: toolTipAnchor.y
                }}>
                {toolTip}
            </span>

            <span // This element is hidden and just for measuring the full width of the text
                ref={ref} 
                className="trunc-measurer">
                {text}
            </span>

            <span 
                className="trunc-ellipsis" 
                style={style}
                onMouseLeave={(e) => hideTruncatedText(e)} 
                onMouseEnter={(e) => revealTruncatedText(e)}>
                {text}
            </span>
        </>
    )
}