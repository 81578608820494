import { Button } from "@mui/material"
import { PanelChildProps } from "./Panel"

export default function PanelLabel ({
    id,
    index,
    title,
    className,
    hidden,
    minimised,
    minimisable,
    maximise = () => {}
}: PanelChildProps) {

    const generateClasses = () => {
        var classes = "panel-label"
        classes = className ? classes.concat(" "+className) : classes
        classes = hidden ? classes.concat(" panel-child-hidden") : classes
        return classes
    }

    const style = {
        left: (index*33.5)+"vw"
    }

    return (
        <div className={generateClasses()} style={style} onClick={() => {maximise()}}>
            <div className="label-content">{title}</div>
            <Button
                color="info"
                disabled={!minimisable}
                className="btn btn-xs collapse-btn"
                aria-hidden={hidden}
                aria-label={`${title} ${minimised ? "expand" : "collapse"}`} >
                {/* {props.minimisable ? <Icons.ChevronLeft className="panel-label-arrow" /> : null} */}
            </Button>
        </div>
    )
}