import { useState } from "react"
import { observer } from "mobx-react-lite"
import { Icons } from "../../../../misc/Icons"
import { Theme } from "../../../../misc/Theme"
import Field from "../../../form/fields/Field"
import { store } from "../../../../stores/Store"
import InlineMenu from "../../../../misc/InlineMenu"
import { Button, CircularProgress } from "@mui/material"
import FormAutomationChart from "../../../../misc/Charts"
import { AlertModal, DisplayDictionary, DisplayValue } from "../../../.."


interface AutomationProps {
    automationNode: any;
}


export default observer(function Automation ({automationNode}: AutomationProps) {

    const {run, loading} = store.AutomationStore

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    const pagesCompletePercent = (outputData.current_page / outputData.total_pages) * 100
    const finishingPagePercentage = (outputData.finishing_page_index / outputData.total_pages) * 100
    var statusColor = outputData.status === "FINISHED" ? Theme.palette.success.main : Theme.palette.error.main

    var genuineColor = Theme.palette.success.main
    var guessedColor = Theme.palette.warning.main
    var falseColor = Theme.palette.error.main
    var runDt = new Date(automationNode.runDt)
    var runDtString = runDt.toLocaleString()
    var expiryDt = new Date(automationNode.expiryDt)
    var expiryDtString = expiryDt.toLocaleDateString()
    var expired = expiryDt < new Date()

    const viewDetails = () => {
        AlertModal({body: <AutomationDetails outputData={outputData} />, size: "lg"})
    }

    if (!automationNode.valid) {
        return (
            <div className="form-automation">
                <div className="form-automation-page-bar">
                    <div className="indicator" style={{width: `${pagesCompletePercent}%`}}></div>
                    <div className="page-limit-indicator" style={{marginLeft: `${finishingPagePercentage}%`}}></div>
                </div>
                
                <div style={{padding: "15px"}}>
                    <div className="form-automation-header">
                        <div style={{display: "flex", alignItems: "end", gap: "10px"}}>
                            <Icons.Automation color="primary" />
                            <h5>{automationNode.label}</h5>
                            <small>This automation is no longer valid</small>
                        </div>

                        {!loading 
                            ? <Button className="btn btn-xs" variant="contained" color="info" onClick={() => run(automationNode.automationId)}>Re-run</Button> 
                            : <CircularProgress style={{width: "14px", height: "14px", margin: "0"}} />}
                        
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="form-automation">
            
            <div className="form-automation-page-bar">
                <div className="indicator" style={{width: `${pagesCompletePercent}%`}}></div>
                <div className="page-limit-indicator" style={{marginLeft: `${finishingPagePercentage}%`}}></div>
            </div>

            <div style={{padding: "15px"}}>
                <div className="form-automation-header">
                    <div style={{display: "flex", alignItems: "end", gap: "10px"}}>
                        <Icons.Automation color="primary" />
                        <h5>{automationNode.label}</h5>
                        <small style={{fontWeight: 600, color: statusColor}}>{outputData.status?.replace("_", " ")}</small>
                    </div>
                    
                    {loading 
                        ? <CircularProgress style={{width: "16px", height: "16px", margin: "0"}} />
                        : (
                            <InlineMenu title="Automation options" menuItems={[
                                {label: "Re-run", action: () => run(automationNode.automationId)},
                                {label: "View data", hidden: !store.AppStore.checkJwtRole("sysuser"), action: () => {
                                    AlertModal({body: JSON.stringify(outputData, null, "\t"), code: true, size: "xl"})
                                }}
                            ]} />
                        )}
                    
                </div>

                <div className="form-automation-details">
                    <div>
                        <p>Pages filled: {outputData.current_page} out of {outputData.total_pages}</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Run at:</td>
                                    <td>{runDtString}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td>{outputData.resume_email}</td>
                                </tr>
                                <tr>
                                    <td>Password:</td>
                                    <td>{outputData.resume_password}</td>
                                </tr>
                            </tbody>
                        </table>
                        
                        <div style={{display: "flex", gap: "15px"}}>
                            {expired ? (
                                <p>This form expired on {expiryDtString}</p>
                            ) : (
                                <Button
                                    className="btn btn-sm" 
                                    variant="contained" 
                                    onClick={() => window.open(outputData.resume_link)}>
                                        Resume
                                </Button>
                            )}
                            
                        </div>
                    </div>

                    <div className="chart">
                        <div>
                            <p>Fields filled: {outputData.total_fields}</p>

                            <table>
                                <tbody>
                                    <tr>
                                        <td><div style={{backgroundColor: genuineColor, padding: "5px", borderRadius: "10px"}}></div></td>
                                        <td>Complete</td>
                                        <td>{outputData.genuine_value_count}</td>
                                    </tr>
                                    <tr>
                                        <td><div style={{backgroundColor: guessedColor, padding: "5px", borderRadius: "10px"}}></div></td>
                                        <td>Needs checking</td>
                                        <td>{outputData.guessed_value_count}</td>
                                    </tr>
                                    <tr>
                                        <td><div style={{backgroundColor: falseColor, padding: "5px", borderRadius: "10px"}}></div></td>
                                        <td>Needs updating</td>
                                        <td>{outputData.false_value_count}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button className="btn btn-sm" variant="contained" color="info" onClick={viewDetails}>View details</Button>
                        </div>

                        

                        <FormAutomationChart
                            labels={["Genuine", "Empty", "Fake"]}
                            chartData={[
                                outputData.genuine_value_count,
                                outputData.guessed_value_count,
                                outputData.false_value_count
                            ]} 
                            colors={[
                                genuineColor,
                                guessedColor,
                                falseColor,
                            ]} 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
})


function AutomationDetails ({outputData}) {

    const valueStatuses = Object.keys(DisplayDictionary("automation_value_validity"))
    const [valueStatusFilter, setValueStatusFilter] = useState(valueStatuses)

    return (
        <div className="auto-form-map-diagnostics">
            
            <div className="header">
                
                <h2>Form Automation - Diagnostics</h2>
                
                <div style={{width: "200px"}}>
                    <Field.MultipleSelect 
                        checkboxes
                        name="field-filter"
                        value={valueStatusFilter}
                        label="Filter fields by validity of answer"
                        warning={false}
                        onChange={(e) => {
                            setValueStatusFilter(e.value)
                        }}
                        options={DisplayDictionary("automation_value_validity", true)} />
                </div>
            </div>

            <div className="diagnostics-field column-headers">
                <span>Field name</span>
                <span>Entered value</span>
                <span>Validity</span>
            </div>
            
            <div style={{maxHeight: "550px", overflow: "auto"}}>
                {outputData?.submitted_pages.map((page, i) => {
                    
                    var fields = page.fields.filter(f => {
                        return valueStatusFilter.includes(f.value_status) && f.type !== "click"
                    })

                    // Don't show page if there are no fields to show after filtering is applied
                    if (valueStatusFilter.length < valueStatuses.length && !fields.length) return <></>
                    
                    return(
                        <div className="diagnostics-section">
                    
                            <div className="diagnostics-section-header">
                                <span>{i + 1} - {page.title}</span>
                                {/* <InlineMenu menuItems={[ {label: "Re-run up to here"} ]} /> */}
                            </div>
                            
                            {fields.map((field, i) => <FieldReadOnly field={field} /> )}
                    
                        </div>
                )})}
            </div>

            {outputData?.validation_messages?.length ? (
                <div className="diagnostics-section">    
                    <div className="diagnostics-field header">
                        <span>Validation messages</span>
                    </div>
                    {outputData?.validation_messages.map((message) => (
                        <div className="diagnostics-field">
                            <span style={{color: Theme.palette.error.main}}>{message}</span>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

function FieldReadOnly ({field}) {

	var fieldStatus = ""
	var label = field.label
        ? field.label.split("\n")[0]
        : field.name.charAt(0).toUpperCase() + field.name.slice(1).replaceAll("_", " ")
    
    if (["true", "false"].includes(field.value)) {
        field.value = field.value.replace("true", "Yes").replace("false", "No")
    }

	if (field.value_status) {
		switch (field.value_status) {
            case "GUESS":
                fieldStatus = "guessed-value" // Amber
                break
            case "EMPTY":
			case "FALSE":
				fieldStatus = "false-value" // Red
				break
			case "GENUINE":
				fieldStatus = "genuine-value"
				break
			default:
				fieldStatus = ""
				break
		}
	}
	return (
		<div className="diagnostics-field">
			<span>{label}</span>
			<span>{field.value}</span>
			<span className={fieldStatus}>{DisplayValue("automation_value_validity", field.value_status)}</span>
		</div>
	)
}