import React from "react"
import Ajax from "../../../Ajax"
import { Alert } from "../../.."
import { format } from "date-fns"
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"
import EnquirySettingsFormSection from "./EnquirySettingsFormSection"
import EnquiryStatusesFormSection from "./EnquiryStatusesFormSection"
import EnquiryGetOptionsFormSection from "./EnquiryGetOptionsFormSection"


interface CreateEnquiryFormProps {
    entityUid: string;
}


export default function CreateEnquiryForm ({entityUid}: CreateEnquiryFormProps) {

    const formId = Math.random().toString()
    const [enquiry, setEnquiry] = React.useState(null)
    const [step, setStep] = React.useState<number>(1)
    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    // Step 1: Get enquiry options for legal system
    const submitStep1 = async (values) => {

        await Ajax.Enquiry.GetOptions(entityUid, values.ruleSystem, values.interestDate).then((response) => {

            setEnquiry({
                subjectUid: entityUid,
                interestDate: format(new Date(values.interestDate), "yyyy-MM-dd"),
                ruleSystem: values.ruleSystem,
                statuses: response.data.childrenObjects.find(c => c.name === "STATUSES"),
                settings: response.data.childrenObjects.find(c => c.name === "SETTINGS")
            })
            setError("")
            setStep(2)

        }).catch(() => Alert({message: "Error getting enquiry options"}))
    }

    // Step 2: Select statuses
    const submitStep2 = (statusesSection) => {
        setEnquiry({...enquiry, statuses: statusesSection})
        setStep(3)        
    }

    // Step 3: Define settings and create enquiry
    const submitStep3 = async (settingsSection) => {

        setError("")
        setSubmitting(true)
        setEnquiry({...enquiry, settings: settingsSection})
        var data = {...enquiry, settings: enquiry.settings.childrenObjects, statuses: enquiry.statuses.childrenObjects}

        return await Ajax.Enquiry.Create(data).then(response => {

            store.AlertStore.Close()
            store.NodeStore.refreshFolderNode()
            store.NodeStore.navigateNode(store.NodeStore.folderNode.uid)
            store.QuestionStore.initForm(null)

        }).catch((response) => {

            var message: string = "Something went wrong"

            // Handle basic string response
            if (typeof response?.data === "string") {
                message = response.data.toString()
                message.includes("interest date") && setStep(1)
            }

            // Handle .Net validation response
            if (response?.data?.detail) {
                message = response.data.detail
            }

            setError(message)
            setSubmitting(false)
        })
    }

    const AlternateFormSection = () => {
        switch (step) {
            case 1:
                return <EnquiryGetOptionsFormSection formId={formId} enquiry={enquiry} submit={submitStep1} />
            case 2:
                return <EnquiryStatusesFormSection formId={formId} submit={submitStep2} section={enquiry.statuses} />
            case 3:
                return <EnquirySettingsFormSection formId={formId} submit={submitStep3} section={enquiry.settings} />
            default:
                return <></>
        }
    }

    return (
        <div className={`form ${submitting ? "disabled" : ""}`} style={{width: "70vw", maxWidth: "300px"}}>

            <div className="form-header">
                <h3>Create enquiry</h3>
            </div>

            <AlternateFormSection />
            
            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                {step > 1 
                    ? <Button color="primary" className="btn btn-sm" onClick={() => setStep(step - 1)} >Back</Button> 
                    : <Button color="primary" className="btn btn-sm" onClick={() => store.AlertStore.Close()} >Cancel</Button>}
                    
                <Button
                    form={formId}
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm">
                    {step === 3 ? "Submit" : "Next"}
                </Button>
            </div>

        </div>
    )
}