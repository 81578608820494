export const dict_key = {
    "accountName": "Account name",
    "parentAccount": "Parent account",
    "label": "Label",
    "accountType": "Account type",
    "customerType": "Customer type",
    "addressLine1": "Address line 1",
    "city": "City",
    "postcode": "Postcode",
    "countryId": "Country ID",
    "status": "Status",
    "regulator": "Regulator",
    "regulatorRegistrationNumber": "Regulator registration number",
    "lastActive": "Last active",
    "createdOn": "Created on",
    "createdBy": "Created by",
    "owner": "Owner",
    "details": "Details",
    "uid": "UID",
}
