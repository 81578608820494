
import { AccountEditForm } from "../components/form/admin/AccountForms";
import { UserCreateForm, UserUpdateForm } from "../components/form/admin/UserForms";
import { CaseCreateForm, CaseEditForm } from "../components/form/case/CaseForms";
import UpdatePasswordForm from "../components/form/admin/UserPasswordUpdateForm";
import EnquiryOutcomeModal from "../components/modals/EnquiryOutcomeModal";
import InlineMenu, { InlineMenuItem } from "./InlineMenu";
import { observer } from "mobx-react-lite";
import { store } from "../stores/Store";
import { Alert, AlertModal } from "..";
import Ajax from "../Ajax";
import Help from "./Help";
import CreateEnquiryForm from "../components/form/enquiry/CreateEnquiryForm";
import IsAllowed from "../models/Permissions";
import DeleteForm from "../components/form/case/DeleteForm";


interface NodeOptionsMenuProps {
    node: any;
    disabled?: boolean;
}


export default observer(function NodeOptionsMenu ({node, disabled = false}: NodeOptionsMenuProps) {

    if (store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false)) return <></>

    if (!node) {
        return <InlineMenu disabled={true} menuItems={[]} />
    }
    
    return (
        <InlineMenu 
            disabled={!!store.AppStore.sessionState || disabled}
            title={store.NodeStore.getDisplayType(node) + " options"}
            menuItems={NodeOptionsMenuItems(node)} />
    )
})


const NodeOptionsMenuItems = (node): InlineMenuItem[] => {

    const {ReportStore, ToolStore, NodeStore} = store

    switch (node.typeName) {
        case "Aora.Platform.Data.AccountNode":
        case "Aora.Nationality.Data.SponsorAccountNode":

            var type = store.NodeStore.getDisplayType(node)?.toLowerCase()
            
            var createChildOrglabel =   <>Create client<Help>
                <p>Clients represent client organisations or individuals.</p>
                <p>They can be used to group users and cases and nationality enquiries.</p>
                <p>Users within a client organisation cannot view data from any other organisation.</p>
            </Help></>
            var createSponsorOrglabel = <>Create sponsor client<Help>
                <p>Sponsor clients represent client organistations which have a license to sponsor visa applicants.</p>
                <p>Sponsor clients can be used to group users and cases and visa enquiries.</p>
                <p>Users within a client organisation cannot view data from any other organisation.</p>
            </Help></>
            
            return [
                // {label: "Create user",                  action: () => AlertModal({title: "Create user", body: <UserCreateForm account={node} /> }),                             hidden: !IsAllowed("AccountNode", "CREATE")},
                // {label: `Edit ${type}`,                 action: () => AlertModal({title: `Edit ${type}`, body: <AccountEditForm account={node} /> }),                           hidden: !IsAllowed("AccountNode", "CREATE") || node.accountType === "admin"},
                // {label: createChildOrglabel,            action: () => AlertModal({title: "Create client", body: <AccountCreateForm parentUid={node.uid} />}),                   hidden: !IsAllowed("AccountNode", "CREATE") || node.accountType === "restricted"},
                // {label: createSponsorOrglabel,          action: () => AlertModal({title: "Create sponsor client", body: <SponsorAccountCreateForm parentUid={node.uid} />}),    hidden: !IsAllowed("SponsorAccountNode", "CREATE") || node.accountType === "restricted"},
                // {label: "Create new AORA customer",     action: () => AlertModal({title: "Create new AORA customer", body: <AccountCreateForm parentUid="ACCOUNT_-1" />}),      hidden: !(IsAllowed("AccountNode", "CREATE") && node.accountType === "admin")},
                // {label: "Switch organisation",          action: () => AlertModal({body: <AccountSelectForm /> }),                                                               hidden: !AppStore.checkJwtRole("sysuser")},
                {label: "Manage account",               action: () => {
                    ToolStore.setAccount(node)
                    ToolStore.setOpen(true)
                    ToolStore.setTool("account")
                    ToolStore.loading = false
                }},
                {label: "Refresh",                      action: () => NodeStore.refreshFolderNode()}
            ]
        case "Aora.Platform.Data.UserNode":
            return [
                {label: "Change password",              action: () => AlertModal({body: <UpdatePasswordForm user={node} />, size: "xs"})},
                {label: "Edit user",                    action: () => AlertModal({title: "Edit user", body: <UserUpdateForm user={node} requestFullUser />}),   hidden: IsAllowed(node, "UPDATE")},
            ]
        case "Aora.Platform.Data.FolderNode":

            var createCaseGuidance = "Nationality cases should be named after the subject's family, visa cases can take the name of visa type."

            return [
                {label: "Create case",                  action: () => AlertModal({body: <CaseCreateForm parentUid={node.uid.replace("FOLDER", "USER")} guidance={createCaseGuidance} />}) },
                {label: "Refresh",                      action: () => NodeStore.refreshFolderNode()}
            ]
        
        case "Aora.Nationality.Data.SponsorNode":
            return [
                {label: "Edit sponsor",                 action: () => { NodeStore.isEditing = true }}
            ]

        case "Aora.Platform.Data.CaseNode":
            return [
                {label: "Create person",                action: () => store.QuestionStore.initForm("CREATE_PERSON")},
                {label: "Edit case",                    action: () => AlertModal({body: <CaseEditForm caseNode={node} />}) },
                {label: "Delete case",                  action: () => AlertModal({body: <DeleteForm node={node} onSuccess={() => {
                    var breadcrumb = store.NodeStore.breadcrumb.filter(nodeRef => nodeRef.uid !== node.uid)
                    store.NodeStore.navigateNode(breadcrumb[breadcrumb.length-1].uid)
                }} />}), hidden: !IsAllowed(node, "DELETE")},
                {label: "Refresh",                      action: () => NodeStore.refreshFolderNode()}
            ]
        
        case "Aora.Nationality.Data.PersonNode":
            return [
                {label: "Edit person",                  action: () => { store.NodeStore.isEditing = true }},
                {label: "New enquiry",                  action: () => AlertModal({body: <CreateEnquiryForm entityUid={node.uid} />, size: "auto"})},
            ]
        
        case "Aora.Platform.Data.EnquiryNode":
            return [
                // {label: store.EnquiryStore.actionLabel(node),   action: () => store.EnquiryStore.progressEnquiry()},
                {label: "Run",                                  action: () => Ajax.Enquiry.Run(node.uid).catch(response => Alert({message: response?.data?.detail}))},
                {label: "Re-run",                               action: () => Ajax.Enquiry.Rerun(node.uid).catch(response => Alert({message: response?.data?.detail}))},
                {label: "View outcome",                         action: () => AlertModal({body: <EnquiryOutcomeModal enquiry={node} />, width: "700px"})},
                // {label: "Automate", action: () => AlertModal({body: <CreateAutomationForm />, size: "md" })}
            ]
        
        case "Aora.Platform.Data.ReportNode":
            return [
                {label: "Download",         action: () => ReportStore.download(node),       disabled: ["R", "F"].includes(node.fileStatus)}, // Disabled if "Requested" or "Failed"
                {label: "Regenerate",       action: () => ReportStore.regenerate(node),     hidden: !IsAllowed("ReportNode", "UPDATE") },
                {label: "Mark as invalid",  action: () => ReportStore.makeInvalid(node),    hidden: !IsAllowed("ReportNode", "UPDATE") },
            ]
    
        default:
            return [
                {label: "No options",       action: () => {}, disabled: true},
                {label: "Refresh",          action: () => NodeStore.refreshFolderNode()}
            ]
    }
}