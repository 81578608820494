import EntityCta from "./EntityCta"
import { observer } from "mobx-react-lite"
import { store, useStore } from "../../../../stores/Store"
import EntityDetailSection from "./EntityDetailSection"
import { flattenNode } from "../../../../misc/nodeFlattener"
import Panel from "../../../layout/Panel"
import PanelLabel from "../../../layout/PanelLabel"
import PanelHeader from "../../../layout/PanelHeader"
import PanelBody from "../../../layout/PanelBody"
import { DetailPanelProps } from "./DetailPanel"


export default observer(function EntityDetail ({index}: DetailPanelProps) {

    const {NodeStore} = useStore()

    const node = NodeStore.leafNode
    const flattenedNode = flattenNode(node)

    return (
        <Panel index={index} id={node?.uid} className="detail" width="100%" loadingContent={NodeStore.loadingLeafNode}>

            <PanelLabel title="Person details" />

            <PanelHeader node={node} closeable onClose={() => store.NodeStore.setLeafNode(null)}>
                <EntityCta entity={node} />
                {node.role ? <small style={{color: "grey"}}>The {node.role}</small> : null}
            </PanelHeader>

            <PanelBody>
                <div className="panel-columns">
                    <div className="panel-column">
                        {flattenedNode.sections.filter((s, i) => (i < (flattenedNode.sections.length/2)) || s.uid.includes("ATTRS")) // Iterate every other section and always show attrs
                            .map((section: any, section_index: number) => <EntityDetailSection entityUid={node.uid} key={section_index} section={section} />)}
                    </div>
                    <div className="panel-column">
                        {flattenedNode.sections.filter((s, i) => !(i < (flattenedNode.sections.length/2)) && !s.uid.includes("ATTRS")) // Iterate every other section
                            .map((section: any, section_index: number) => <EntityDetailSection entityUid={node.uid} key={section_index} section={section} />)}
                    </div>
                </div>
            </PanelBody>

        </Panel>
    )
})