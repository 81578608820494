var allowableCertainties = []


export const flattenNode = (node) => { // Return flat person

    var person = {...node}

    delete person.childrenObjects
    delete person.validChildTypeStrings
    delete person.childrenLoaded
    delete person.childrenSorted

    var nonAbsenceSections = node.childrenObjects.filter(section => !section.label.includes("Absence") && !section.label.includes("Presence"))
    person.sections = nonAbsenceSections.map(section => {
        return flattenSection(section, [], [person.uid])
    }).reverse()


    // Handle absence sections defferently; flatten the top 2 nodes
    var absenceSections = node.childrenObjects.filter(section => section.label.includes("Absence"))
    absenceSections.forEach(absenceSection => {
        absenceSection.childrenObjects.forEach(absenceUserCountry => {
            var absence = flattenSection(absenceUserCountry, ["Absence from "], [person.uid])
            person.sections.push(absence)
        })
    })

    // Handle presence sections defferently; flatten the top 2 nodes
    var presenceSections = node.childrenObjects.filter(section => section.label.includes("Presence"))
    presenceSections.forEach(presenceSection => {
        presenceSection.childrenObjects.forEach(presenceUserCountry => {
            var label = "Presence in " + presenceUserCountry.label
            presenceUserCountry.label = ""
            var presence = flattenSection(presenceUserCountry, [label], [person.uid])
            person.sections.push(presence)
        })
    })

    return person
}


const flattenSection = (section, parentLabels = [], parentUids = []) => { // return flat section

    var label = section.label ? [...parentLabels, section.label] : parentLabels

    var newSection = {
        label: label,
        rows: [],
        statusHeading: [],
        subSections: [],
        uid: section.uid,
        canAppend: section.typeName === "Aora.Nationality.Data.ListNode" && section.label.includes("Jobs"),
        canEdit: section.canEdit,
        canDelete: section.canDelete,
        uidPath: [...parentUids, section.uid]
    }

    handleChildren(section.childrenObjects, newSection, label, newSection.uidPath)

    return newSection
}


const handleChildren = (children, newSection, parentLabels = [], parentUids = []) => { // Mutate section in 2nd argument

    children?.forEach(child => {

        switch (child.typeName) {
            // Section node

            case "Aora.Nationality.Data.AbsenceBlockNode":
                newSection.subSections.push({...child, label: [child.label], rows: child.absenceItems, statusHeading: [], subSections: []})
                break

            case "Aora.Nationality.Data.ListNode":
            case "Aora.Nationality.Data.StructNode":
            case "Aora.Nationality.Data.DictNode":

                var subSection = flattenSection(child, parentLabels, parentUids)
                newSection.subSections.push(subSection)
                break

            // Branch nodes
            case "Aora.Nationality.Data.LStatusNode":
            case "Aora.Platform.Data.SectionNode":

                if (child.typeName === "Aora.Nationality.Data.LStatusNode" && !child.label) {
                    allowableCertainties = child.allowableCertainties
                }

                var SectionNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Platform.Data.SectionNode")
                handleChildren(SectionNodes, newSection, parentLabels, parentUids)
                var DictNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.DictNode")
                handleChildren(DictNodes, newSection, parentLabels, parentUids)
                var ListNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.ListNode")
                handleChildren(ListNodes, newSection, parentLabels, parentUids)
                var LStatusNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.LStatusNode")
                handleChildren(LStatusNodes, newSection, parentLabels, parentUids)
                var StructNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.StructNode")
                handleChildren(StructNodes, newSection, parentLabels, parentUids)
                var AttributeNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Platform.Data.AttributeNode")
                handleChildren(AttributeNodes, newSection, parentLabels, parentUids)
                var LStatusItemNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.LStatusItemNode")
                handleChildren(LStatusItemNodes, newSection, parentLabels, parentUids)
                var NameNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.NameNode")
                handleChildren(NameNodes, newSection, parentLabels, parentUids)
                break

            // Leaf nodes
            case "Aora.Nationality.Data.NameNode":
                newSection.rows.push({
                    ...child,
                    label: child.currency.join(", "),
                    value: child.label,
                    certainty: "CERTAIN",
                    parentUids: parentUids
                })
                break

            case "Aora.Platform.Data.AttributeNode":
                newSection.rows.push(child)
                break

            case "Aora.Nationality.Data.LStatusItemNode":
                newSection.statusHeading.push({...child, allowableCertainties: allowableCertainties})
                break

            default:
                break
        }
    })
}


