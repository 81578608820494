import {
    InputLabel
} from "@mui/material";
import CheckboxField from "./CheckboxField";
import { SelectOption } from "./SelectField";



export interface CheckboxSelectFieldProps {
    name?: string;
    value?: any[];
    label?: string;
    formik?: boolean;
    warning?: any;
    helpText?: string;
    onChange: Function;
    disabled?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
    options: SelectOption[];
}


const CheckboxOption = ({option, selectedValues, onChange}) => {

    const opt = {
        disabled: false,
        ...option
    }

    return (
        <>
            <CheckboxField
                formik
                name={option.value}
                label={option.label}
                disabled={opt.disabled}
                value={selectedValues.includes(option.value)}
                onChange={onChange}  />
            
            {option.children ? (
                <div className="checkbox-select-list" style={{paddingLeft: "18px"}}>
                    {option.children?.map((child, i) => <CheckboxOption key={i} option={child} selectedValues={selectedValues} onChange={onChange} />)}
                </div>
            ) : null}
        </>
    )
}


export const CheckboxSelectField = ({

    name,
    value = [],
    label = "",
    options,
    onChange,
    warning = "",
    helpText = "",
    formik = false,
    disabled = false,
    autoFocus = false,
    placeholder = "",

}: CheckboxSelectFieldProps) => {

    const getOptionByValue = (optionsList, value): any => {
        
        for (var i = 0; i < optionsList.length; i++) {

            var option = optionsList[i]
        
            if (option.value === value) {
                return option
            } 

            if (option.children) {
                var valueFromChild = getOptionByValue(option.children, value)

                if (valueFromChild) {
                    return valueFromChild
                }
            }
        }

        return null
    }

    const getChildValues = (value) => {

        var values = [value]
        var option = getOptionByValue(options, value)
        
        option?.children?.forEach((child) => {
            values.push(...getChildValues(child.value))
        })

        return values
    }

    const handleChange = (e) => {
        
        var newSelection
        var selectedValues = getChildValues(e.target.name)

        if (value.includes(e.target.name)) { // Remove
            newSelection = value.filter(v => !selectedValues.includes(v))

        } else { // Add
            newSelection = [...value, ...selectedValues]
        }

        // Remove duplicate values
        newSelection = Array.from(new Set(newSelection))

        if (!formik) {
            onChange({name: name, value: newSelection})
            return 
        }

        var eventProx: any = {
            target: {
                name: name,
                value: newSelection,
                type: "string"
            }
        }

        onChange(eventProx)
    }

    var classes = "form-field field-checkbox-select"
    classes = disabled ? `${classes} disabled` : classes

    return (
        <div className={classes}>

            {label ? <InputLabel>{label}</InputLabel> : null}

            <div className="checkbox-select-list">
                {options.map((option, i) => <CheckboxOption key={i} option={option} selectedValues={value} onChange={handleChange} /> )}
            </div>

            <span className="warning">{warning}</span>

            {helpText ? <span className="help-text">{helpText}</span> : null}

        </div>
    )
}