import React from "react"
import Ajax from "../../../Ajax"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"


export default function DeleteForm ({node, onSuccess = () => {}}) {

    const [labelConfirmation, setLabelConfirmation] = React.useState("")
    const [success, setSuccess] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState<string | false>(false)
    const typeName = store.NodeStore.getDisplayType(node)
    const displayName = store.NodeStore.getDisplayName(node)

    const deleteNode = async () => {

        if (displayName !== labelConfirmation) {
            return setErrorMessage(`The confirmation text does not match the ${typeName}'s name`)
        }   
        
        await Ajax.Node.Delete(node.uid).then(() => {
            // var breadcrumb = store.NodeStore.breadcrumb.filter(nodeRef => nodeRef.uid !== node.uid)
            // store.NodeStore.navigateNode(breadcrumb[breadcrumb.length-1].uid)
            setSuccess(true)
            onSuccess()
        }).catch((response) => {
            var message = response.data?.detail ? response.data?.detail : "Something went wrong"
            setErrorMessage(message)
        })
        
    }

    if (success) return <h4>This {typeName} was successfully deleted</h4>

    return (
        <form className="form" onSubmit={(e) => {
            e.preventDefault()
            deleteNode()
        }}>
            <div className="form-header">
                <h3>Delete {typeName}</h3>
            </div>

            <div className="form-fields">
                <p>
                    Please type "{displayName}" below to confirm that you want to permanently
                    delete this {typeName} and all of the data and entities it contains.
                </p>

                <Field.Text
                    formik
                    label="Delete confirmation"
                    value={labelConfirmation}
                    onChange={(e) => setLabelConfirmation(e.target.value)}
                    warning={errorMessage} />
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" variant="contained" color="success">Cancel</Button>
                <Button
                    className="btn btn-sm"
                    variant="contained"
                    color="error"
                    type="submit"
                    disabled={displayName !== labelConfirmation}>
                    Delete
                </Button>
            </div>
        </form>
    )
}