import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import Panel from '../../layout/Panel'
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons'
import { store } from '../../../stores/Store'
import PanelBody from '../../layout/PanelBody'
import PanelLabel from '../../layout/PanelLabel'
import PanelHeader from '../../layout/PanelHeader'
import FolderPanelListItem from "./FolderPanelListItem"
import TruncText from '../../../misc/TruncText'


export default observer(function FolderPanel () {

    var panelWidth = "270px"
    var {folderNode, getFolderChildren} = store.NodeStore
    var classes = store.NodeStore.loading && getFolderChildren?.length ? "panel-folder disabled" : "panel-folder"
    var displayType = store.NodeStore.getDisplayType(folderNode)
    var displayName = store.NodeStore.getDisplayName(folderNode)

    return (
        <Panel index={0} id={folderNode?.uid} className={classes} width={panelWidth} minimisable loadingContent={!folderNode}>

           <PanelLabel title={
                folderNode 
                    ? <div className="trunc-ellipsis">{`${displayType}: ${displayName}`}</div> 
                    : <CircularProgress style={{width: "10px", height: "10px", margin: 0}} />
            } />

           <PanelHeader node={folderNode} />

            <PanelBody>
                <FolderPanelList />
            </PanelBody>

        </Panel>
    )
})


function FolderPanelList () {
    
    const {folderNode, getFolderChildren} = store.NodeStore
    const displayType = store.NodeStore.getDisplayType(folderNode)
    const empty = !getFolderChildren.filter((node) => !node.typeName.includes("Sponsor"))?.length
    var nodesWithGroupedChildren = ["Aora.Platform.Data.CaseNode"]
    var groupedNodes = {}

    getFolderChildren?.forEach((node: any) => {
        var typeDisplayName = store.NodeStore.getDisplayType(node, true)
        if (typeDisplayName in groupedNodes) {
            groupedNodes[typeDisplayName].push(node)
        } else {
            groupedNodes[typeDisplayName] = [node]
        }
    })

    if (empty) return (
        <small style={{margin: "10px 0"}}>
            Click <Icons.More style={{fontSize: "12px", margin: "0 2px -3px 2px"}} /> to
            {folderNode.typeName === "Aora.Platform.Data.CaseNode" ? " start building your case" : " add a new case"}
        </small>
    )

    if (nodesWithGroupedChildren.includes(folderNode.typeName)) return (
        <nav id="folder-panel-list" style={{marginTop: "6px"}}>
            {Object.keys(groupedNodes)?.map((groupName, i) => 
                <ListGroup key={`${folderNode?.uid}${i}}`} groupName={groupName} list={groupedNodes[groupName]} />
            )}
        </nav>
    )

    return (
        <nav id="folder-panel-list">
            {getFolderChildren?.map((node: any, i) => <FolderPanelListItem key={i} node={node} />)}
        </nav>
    )
}


function ListGroup ({groupName, list}) {

    const [minimised, setMinimised] = React.useState(false)

    return (
        <>
            <div className="menu-divider">
                <Button className="btn-list-divider" onClick={() => setMinimised(!minimised)}>
                    <h6>{groupName}</h6>
                    <Icons.ArrowRight style={{fontSize: "16px", padding: "0", transform: minimised ? "rotate(0)" : "rotate(90deg)"}} />
                </Button>
            </div>
            
            {!minimised ? list?.map((node: any, i) => <FolderPanelListItem key={i} node={node} />) : <div style={{padding: "3px"}}></div>}
        </>
    )
}