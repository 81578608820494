import React from "react"
import { format } from "date-fns"
import { isValid } from "date-fns/esm"
import Help from "../../../../misc/Help"
import { DisplayValue } from "../../../.."
import { observer } from "mobx-react-lite"
import { Icons } from "../../../../misc/Icons"
import { Theme } from "../../../../misc/Theme"
import { store } from "../../../../stores/Store"
import TruncText from "../../../../misc/TruncText"
import { Report } from "../../../../models/Report"
import NodeOptionsMenu from "../../../../misc/NodeMenus"
import { CircularProgress } from "@mui/material"

interface ReportProps {
    report: Report;
}

export default observer(function Report ({report}: ReportProps) {

    var enquiry = store.EnquiryStore.enquiry
    var fileStatus = DisplayValue("report_file_status", report.fileStatus)
    if (enquiry.status === "REPORT_DATA_REQUIRED") {
        fileStatus = "More data required"
    }

    const dateCell = () => {
        if (report.fileStatus === "R" && enquiry.status === "DEFINITIVE") {
            return <CircularProgress style={{width: "10px", height: "10px"}} />
        }

        if (isValid(report.generatedAt)) {
            return format(new Date(report.generatedAt), "dd-MM-yyyy")
        }

        return format(new Date(report.requestedAt), "dd-MM-yyyy")
    }

    const StatusCell = () => {

        var warning: string | false = false

        if (report.fileStatus !== "R" && !report.valid) {
            warning = "This report is not valid."
        }
        if (report.fileStatus === "X") {
            warning = "This report generated with errors."
        }
        if (report.fileStatus === "F") {
            warning = "This report failed to generate."
        }
        if (warning) {
            return <Help content={<Icons.Warning />} >{warning}</Help>
        }
        
        return <Icons.Report style={{color: Theme.palette.primary.main}} />
    }

    return (
        <div className="section-row report-row">
            <div className="section-cell" style={{maxWidth: "20px"}}>
                <StatusCell />
            </div>
            <div className="section-cell">
                <TruncText text={report.label} />
            </div>
            <div className="section-cell" style={{maxWidth: "100px"}}>
                <TruncText text={DisplayValue("report_type", report.reportType)} />
            </div>
            <div className="section-cell" style={{maxWidth: "75px"}}>
                <TruncText text={dateCell()} />
            </div>
            <div className="section-cell" style={{maxWidth: "54px"}}>
                {report.outcome?.includes("Y")
                    ? <div style={{color: "green", fontSize: "12px"}}>Held</div>
                    : <div style={{color: Theme.palette.error.main, fontSize: "12px"}}>Not held</div>}
            </div>
            <div className="section-cell" style={{maxWidth: "20px"}}>
                <NodeOptionsMenu node={report} />
            </div>
        </div>
    )
})