import React from 'react'
import * as Yup from 'yup'
import Ajax from '../../../Ajax'
import { useFormik } from "formik"
import Field from '../fields/Field'
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"


export default function UpdatePasswordForm ({user}) {
    
    // account managers can update anyones password
    if (store.AppStore.checkJwtRole("account_manager")) {
        return <UpdateAnyPassword user={user} />
    }

    // non account managers can only update their own password, using a dedicated message
    if (user.uid === store.AppStore.sessionInfo.userUid) {
        return <UpdateOwnPassword />
    }
    
    return <h4>You are not authorized to do this</h4>
}


function UpdateOwnPassword () {

    const [success, setSuccess] = React.useState(false)

    var formik = useFormik({

        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        },

        validationSchema: Yup.object({
            currentPassword: Yup.string().required("This field if required"),
            newPassword: Yup.string().required("This field if required").matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                "Your new password must contain at least 8 characters with 1 lowercase letter, 1 uppercase and one number"),
        }),

        onSubmit: async (values) => {
            Ajax.Session.UpdatePassword(values).then(() => {
                setSuccess(true)
            }).catch((response) => {
                formik.setErrors({newPassword: response.data.detail})
            })
        }
    })

    if (success) return <h4>Your password has been reset</h4>

    return (
        <form autoComplete="off" className={`form`} onSubmit={formik.handleSubmit}>
            <div className="form-fields">
                <Field.Text
                    formik
                    type="password"
                    label="Current password"
                    name="currentPassword"
                    onChange={formik.handleChange}
                    value={formik.values.currentPassword}
                    warning={formik.submitCount ? formik.errors.currentPassword : null} />

                <Field.Text
                    formik
                    type="password"
                    label="New password"
                    name="newPassword"
                    onChange={formik.handleChange}
                    value={formik.values.newPassword}
                    warning={formik.submitCount ? formik.errors.newPassword : null} />
            </div>

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => store.AlertStore.Close()}>
                    Back
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={formik.isSubmitting}>
                    Submit
                </Button>
            </div>

        </form>
    )
}


function UpdateAnyPassword ({user}) {

    var formik = useFormik({

        initialValues: {
            password: "",
        },

        validationSchema: Yup.object({
            password: Yup.string().required("This field is required").matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                "Your new password must contain at least 8 characters with 1 lowercase letter, 1 uppercase and one number"),
        }),

        onSubmit: async (values) => {

            var userPayload = {
                uid: user.uid,
                password: values.password,
                canEdit: true,
                canUpdate: true
            }
            
            await Ajax.Node.Edit("user", userPayload).then(() => {
                store.AlertStore.Close()
                store.NodeStore.refreshLeafNode()
            }).catch((response) => {
                var message = response.data?.detail ? response.data?.detail : "An error occured"
                formik.setErrors({password: message})
            })
        }
    })

    return (
        <form autoComplete="off" className={`form`} onSubmit={formik.handleSubmit}>
            <div className="form-fields">
                <Field.Text
                    formik
                    type="password"
                    label="New password"
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    warning={formik.submitCount ? formik.errors.password : null} />
            </div>

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => store.AlertStore.Close()}>
                    Back
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    disabled={formik.isSubmitting}>
                    Submit
                </Button>
            </div>
        </form>
    )
}