import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import IsAllowed from "../../../models/Permissions"
import DeleteForm from "../../form/case/DeleteForm"
import { AlertModal, DisplayValue } from "../../.."
import { Button, CircularProgress } from "@mui/material"
import { AccountEditForm } from "../../form/admin/AccountForms"
import { ToolDetailRow } from "../profile_mgmt/ProfileMgmtTool"
import { Link } from "react-router-dom"
import { Icons } from "../../../misc/Icons"


export default observer(function AccountMgmtDetails () {

    const { sessionState } = store.AppStore
    const { account, loading, getAccount, setTool, setAccount } = store.ToolStore

    if (account?.uid === "ACCOUNT_0") return <></>

    if (!account || loading) return <CircularProgress color="info" />

    return (
        <div className="tool-section">
            <h3>Account details</h3>

            <div className="tool-table">

                <ToolDetailRow label={DisplayValue("dict_key", "accountName")}                  value={account.accountName} />
                <ToolDetailRow label={DisplayValue("dict_key", "uid")}                          value={account.uid} />
                <ToolDetailRow label={DisplayValue("dict_key", "parentAccount")}                value={account.parentAccount?.name} hidden={account.accountType === "customer"} />
                <ToolDetailRow label={DisplayValue("dict_key", "owner")}                        value={account.owner?.name} />
                <ToolDetailRow label={DisplayValue("dict_key", "createdBy")}                    value={account.createdBy?.name} />
                <ToolDetailRow label={DisplayValue("dict_key", "accountType")}                  value={store.NodeStore.getDisplayType(account)} />
                <ToolDetailRow label={DisplayValue("dict_key", "customerType")}                 value={DisplayValue("customer_type", account.customerType)} />
                <ToolDetailRow label={DisplayValue("dict_key", "phone")}                        value={account.phone} />
                <ToolDetailRow label={DisplayValue("dict_key", "addressLine1")}                 value={account.addressLine1} />
                <ToolDetailRow label={DisplayValue("dict_key", "city")}                         value={account.city} />
                <ToolDetailRow label={DisplayValue("dict_key", "postcode")}                     value={account.postcode} />
                <ToolDetailRow label={DisplayValue("dict_key", "countryId")}                    value={DisplayValue("countries", account.countryId)} />

                {/* <ToolDetailRow label={DisplayValue("dict_key", "regulator")}                    value={DisplayValue("ni_regulator", account.regulator)} hidden={account.accountType !== "customer"} />
                <ToolDetailRow label={DisplayValue("dict_key", "regulatorRegistrationNumber")}  value={account.regulatorRegistrationNumber} hidden={account.accountType !== "customer"} /> */}

                {/* <ToolDetailRow label={DisplayValue("dict_key", "createdOn")}                    value={account.createdOn} /> */}
                {/* <ToolDetailRow label={DisplayValue("dict_key", "lastActive")}                   value={account.lastActive} /> */}
            </div>

            <div className="button-group">
                <Button 
                    color="success" 
                    variant="contained" 
                    className="btn btn-sm" 
                    component={Link} to={`/app/main/${account.uid}`}>
                    Go to workspace
                </Button>

                {IsAllowed(account, "UPDATE") ? (
                    <Button
                        color="info"
                        variant="contained"
                        className="btn btn-sm"
                        disabled={!!sessionState}
                        onClick={() => AlertModal({title: `Edit account`, body: <AccountEditForm account={account} onSuccess={getAccount} /> })}>
                        Edit account
                    </Button>
                ) : null}

                {IsAllowed(account, "DELETE") ? (
                    <Button
                        color="error"
                        variant="contained"
                        className="btn btn-sm"
                        disabled={!!sessionState}
                        onClick={() => {
                            AlertModal({body: <DeleteForm node={account} onSuccess={() => {
                                setTool(null)
                                setAccount(null)
                                store.NodeStore.refreshAccounts()
                            }} /> })
                        }}>
                        Delete account
                    </Button>
                ) : null}
            </div>
        </div>
    )
})