import { Button } from "@mui/material";
import { store } from "../../stores/Store"
import NodeMenus from "../../misc/NodeMenus"
import { Icons, TypeIcon } from "../../misc/Icons"
import { PanelChildProps } from "./Panel";

export default function PanelHeader (props: PanelChildProps) {

    const generateClasses = () => {
        var classes = "panel-header"
        classes = props.className ? classes.concat(" "+props.className) : classes
        classes = props.hidden || props.minimised ? classes.concat(" panel-child-hidden") : classes
        return classes
    }

    const Wrapper = ({children}) => {
        return (
            <div className={generateClasses()} aria-hidden={props.minimised} tabIndex={props.minimised ? -1 : null}>
                <div className="panel-header-content">
                    {children}
                </div>
                <hr/>
            </div>
        )
    }

    if (props.loadingContent) return <Wrapper><img className="loading-placeholder shimmer" src="/images/header placeholder.png" alt="list placeholder" /></Wrapper>

    if (!props.node) return <Wrapper>{props.children}</Wrapper>

    var label = store.NodeStore.getDisplayName(props.node)

    return (
        <Wrapper>
            <div style={{display: "grid", gap: "2px"}}>
                <h4 className="trunc-ellipsis">{label}</h4>
                <small className="panel-header-node-type"><TypeIcon node={props.node} /> {store.NodeStore.getDisplayType(props.node)}</small>
            </div>
            
            {props.children}

            <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                <NodeMenus node={props.node} />

                {/* {props.closeable && <Button className="btn btn-sm" color="info" onClick={props.onClose}><Icons.X /></Button>} */}
            </div>
        </Wrapper>
    )
}