import { format } from "date-fns";

export default class FieldInfo {
    
    typeName: string;
    datatype: "DATE" | "DATETIME" | "DOUBLE" | "INTEGER" | "OBJECT" | "OBJECT_ARRAY" | "STRING" | "STRING_ARRAY";
    minValueCount: number;
    maxValueCount: number;
    style: "CHECKBOX" | "DATE" | "DROPDOWN" | "LISTBOX" | "RADIO" | "TEXTBOX";
    label: string;

    hasSecondaryCheckbox: boolean;
    secondaryCheckboxValues: any[];
    secondaryCheckboxLabel: string;

    requiredMessage: string;
    allowedCertainties: string[];
    certaintyMessages: string[];
    prepopulated: boolean;
    assumedValuesObj: object[];
    enteredValuesObj: object[];
    enteredCertainties: string[];
    help: any;
    nullable: boolean;
    singleValued: boolean;
    numberValuesEntered: number;
    numberCertaintiesEntered: number;
    certaintiesRequired: boolean;

    // Select
    availableValues: any[];
    selectedCertainty: string;
    searchFacility: string;

    // Text
    minChars: number;
    maxChars: number;

    // Integer
    minValue: number;
    maxValue: number;

    // Date
    minDate: string;
    maxDate: string;

    constructor(obj: Object) {

        // Assign json values to this
        Object.keys(obj).forEach((key) => {

            switch (key) {

                // Fake a message here for testing purposes
                case "certaintyMessages":
                    // if (obj[key].length) {
                    //     obj[key][obj[key].length -1] = "If unknown, we will assume a value of '123'"
                    // }

                    this[key] = obj[key]
                    break

                // Fake min and max dates
                case "minDate":
                case "maxDate":
                    var defaultValue = key === "minDate" ? "1700-01-01" : format(new Date(), "yyyy-MM-dd")

                    obj[key] === "0001-01-01T00:00:00"
                        ? this[key] = defaultValue
                        : this[key] = obj[key]
                    break

                default:
                    this[key] = obj[key]
            }
        })
    }
}