import React from "react";
import Ajax from "../../Ajax";
import { format } from "date-fns";
import { store } from "../../stores/Store";
import { observer } from "mobx-react-lite";
import { AlertModal, CloseModal } from "../..";
import { Button, CircularProgress } from "@mui/material";
import ReportRequestForm from "../form/report/ReportRequestForm";
import EnquiryStatusSection from "../views/main/detail/EnquiryStatusSection";


// This component can be invoked using EITHER an enquiry object OR and enquiry uid.
// If an ID is provided, a HTTP request must be made to get the enquiry.


interface EnquiryOutcomeModalProps {
    enquiryUid?: string;
    enquiry?: any;
}

export default observer(function EnquiryOutcomeModal ({enquiryUid = null, enquiry = null}: EnquiryOutcomeModalProps) {

    const [enquiryData, setEnquiryData] = React.useState(enquiry)

    const getEnquiry = async () => {
        if (enquiryData === null && enquiryUid) {
            await Ajax.Node.Get(enquiryUid)
                .then(response => setEnquiryData(response.data))
        }
    }

    React.useEffect(() => {
        setTimeout(() => {
            getEnquiry()
        }, 3000)
    })

    if (enquiryData === null) {
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <CircularProgress style={{width: "16px", height: "16px", margin: "10px"}} />
                <h5>Generating outcome...</h5>
            </div>
        )
    }

    var subjectName = enquiryData.subjects.length 
        ? enquiryData.subjects[0].name
        : "the subject" 

    var restricted = store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false)

    switch (enquiryData.status) {

        case "FINAL":
        case "INTERIM":
            
            return (
                <div style={{display: "grid", gap: "10px"}}>
                    <h2>Interim result</h2>
                    <p>We have reached an outcome for {subjectName} under the law of the {enquiryData.ruleSystem}.</p>
                    <p>There is still some information needed to complete the full enquiry and generate reports, choose to continue below.</p>
                    
                    <EnquiryStatusSection enquiry={enquiryData} editable={false} showCertainty={false} maxHeight="400px" />
                    
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        <Button 
                            className="btn btn-sm" 
                            variant="contained" 
                            onClick={() => CloseModal().then(() => Ajax.Enquiry.Run(enquiryData.uid))}>
                            Complete now
                        </Button>
                    </div>
                </div>
            )

        case "DEFINITIVE":
            return (
                <div style={{display: "grid", gap: "10px"}}>
                    <h2>Full outcome reached</h2>

                    {!restricted 
                        ? <p>Outcome of determination for {subjectName} under the law of the {enquiryData.ruleSystem} on {format(new Date(enquiryData.interestDate), "dd LLLL yyyy")}</p>
                        : <p>Thank you for supplying all of the required information</p>}
                    
                    <EnquiryStatusSection enquiry={enquiryData} editable={false} maxHeight="400px" />
                    
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        {!restricted && <Button 
                            className="btn btn-sm" 
                            variant="contained" 
                            onClick={() => {
                                store.AlertStore.Close()
                                AlertModal({body: <ReportRequestForm enquiry={enquiryData} />, width: "500px"})
                            }}>
                            Order reports
                        </Button>}
                    </div>
                </div>
            )

        case "RERUN_REQUIRED":
            return (
                <div style={{display: "grid", gap: "10px"}}>
                    <h2>A re-run is required</h2>
                    <p>
                        An outcome of determination for {subjectName} under the law of the {enquiryData.ruleSystem} had previously been reached, 
                        however, subsequent changes in the relevant data have made the result invalid and requires you to re-run the enquiry.
                    </p>
                    
                    <EnquiryStatusSection enquiry={enquiryData} editable={false} maxHeight="400px" />
                    
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        <Button 
                            className="btn btn-sm" 
                            variant="contained" 
                            onClick={() => CloseModal().then(() => Ajax.Enquiry.Run(enquiryData.uid))}>
                            Re-run
                        </Button>
                    </div>
                </div>
            )

        default:
            return (
                <div style={{display: "grid", gap: "10px"}}>
                    <h2>No outcome reached</h2>
                    <p>Continue running the enquiry in order to reach an outcome</p>
                </div>
            )
    }

})