import React from "react"
import * as Yup from "yup"
import Ajax from "../../Ajax"
import { AlertModal } from "../.."
import { useFormik } from "formik"
import Field from "./fields/Field"
import { Button, CircularProgress } from "@mui/material"


export default function TicketRequestForm ({title = "", description = ""}) {

    const [error, setError] = React.useState("")
    const [sent, setSent] = React.useState(false)

    const formik = useFormik({

        initialValues: {
            title: title,
            description: description,
            files: []
        },

        validationSchema: Yup.object({
            title: Yup.string().required("Please enter a title"),
            description: Yup.string().required("Please describe the problem"),
            files: Yup.mixed().test("files", "Please select at least one file", (files) => {
                files.forEach(file => {
                    if (file.size > 300) return false
                })
                return true
            }),
        }),

        onSubmit: async (values, actions) => {

            setError("")
            var formData = new FormData()
            formData.append("title", formik.values.title)
            formData.append("description", formik.values.description)
            formik.values.files.forEach(file => formData.append("files", file))

            return await Ajax.Session.TicketRequest(formData).then(() => {
            
                formik.resetForm()  
                setSent(true)
            
            }).catch((response) => 
                setError(response.data.detail)
            )
        }
    })

    if (sent) return <h4>Thank you for helping us improve our system!</h4>

    return (
        <form className="form" onSubmit={formik.handleSubmit}>

            <h2 onClick={() => AlertModal({size: "lg", code: true, body: JSON.stringify(formik, null, "\t")})}>Report a problem</h2>

            <div className="form-fields">
                <Field.Text
                    formik
                    name="title"
                    label="Title"
                    warning={formik.submitCount ? formik.errors.title : null}
                    value={formik.values.title}
                    spellCheck={true}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    name="description"
                    label="Description"
                    rows={4}
                    spellCheck={true}
                    warning={formik.submitCount ? formik.errors.description : null}
                    value={formik.values.description}
                    onChange={formik.handleChange} />

                <Field.File 
                    formik
                    name="files"
                    onChange={formik.handleChange}
                    value={formik.values.files}
                    warning={formik.submitCount ? formik.errors.files : null}
                    label="Upload any evidence of your bug" />
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                {/* <div></div> */}
                <Button style={{width: "70px"}} className="btn-md" disabled={formik.isSubmitting} type="submit" color="primary" variant="contained">
                    {formik.isSubmitting ? <CircularProgress /> : "Submit"}
                </Button>
            </div>
        </form>
    )
}