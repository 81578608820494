import { observer } from "mobx-react-lite"
import { DisplayValue } from "../../.."
import { store } from "../../../stores/Store"
import Field from "../../form/fields/Field"

export default observer(function ReportFilterForm () {

    const {ReportStore} = store

    return (
        <div className="form" style={{maxWidth: "300px"}}>
            <div className="form-fields">
                <Field.Text
                    formik
                    label="Search"
                    value={ReportStore.searchString}
                    warning={false}
                    helpText="Searches the 'Subject' and 'Case' and 'Requested by' columns, not case sensitive."
                    onChange={(e) => {
                        ReportStore.searchString = e.target.value
                    }}/>

                <Field.Select
                    formik
                    label="Order by"
                    value={ReportStore.orderedBy}
                    warning={false}
                    onChange={(e) => {
                        ReportStore.orderedBy = e.target.value
                    }}
                    options={[
                        {label: DisplayValue("label", "Subject"), value: "subject"},
                        {label: DisplayValue("label", "Family"), value: "case"},
                        {label: DisplayValue("label", "Account"), value: "account"},
                        {label: DisplayValue("label", "Requested by"), value: "requestedBy"},
                        {label: DisplayValue("label", "Report type"), value: "reportType"},
                        {label: DisplayValue("label", "Legal system"), value: "legalSystem"},
                        {label: DisplayValue("label", "Nationality"), value: "statusId"},
                        {label: DisplayValue("label", "File"), value: "fileStatus"},
                        {label: DisplayValue("label", "RLID"), value: "reportLanguageId"},
                        {label: DisplayValue("label", "Version"), value: "version"},
                        {label: DisplayValue("label", "Requested"), value: "requestedAt"},
                        {label: DisplayValue("label", "Valid"), value: "valid"},
                    ]} />

                <Field.Select
                    formik
                    label="Order"
                    warning={false}
                    value={ReportStore.order}
                    onChange={(e) => {
                        ReportStore.order = e.target.value
                    }}
                    options={[
                        {label: "Descending", value: "desc"},
                        {label: "Ascending", value: "asc"},
                    ]} />
            </div>
        </div>
    )
})