import Automation from "./Automation";
import { observer } from "mobx-react-lite";
import { store } from "../../../../stores/Store";
import { Button, CircularProgress } from "@mui/material";
import ErrorBoundary from "../../../../misc/ErrorBoundary";
import { provisions } from "../../../../misc/4C Provisions";
import { Theme } from "../../../../misc/Theme";
import Help from "../../../../misc/Help";


export default observer(function NextStepsSection () {

    var { enquiryAutomations } = store.AutomationStore
    var { enquiry, enquiryStatuses } = store.EnquiryStore
    var UKBC_P = enquiryStatuses.find(s => s.code === "UKBC_P" && s.value === "Y")

    if (enquiry.status !== "DEFINITIVE" || !UKBC_P) {
        return <></>
    }

    return (
        <ErrorBoundary>
            <div className="detail-section">
                <div className="section-header">
                    <div className="header-content" style={{justifyContent: "left", gap: "7px"}}>
                        <h5>Next steps</h5>
                        <Help content={<h5 style={{color: Theme.palette.primary.main}}><i>BETA</i></h5>}>
                            This is a new feature which we will be continually improving, we hope you find it useful!
                        </Help>
                    </div>
                </div>

                <div className="section-subheader">
                    {!enquiryAutomations?.length
                        ? <h5>Apply under the subjects eligibility for British citizenship</h5>
                        : null}
                </div>

                {enquiryAutomations?.length 
                    ? enquiryAutomations.map((aNode, i) => <Automation automationNode={aNode} />)
                    : <NextStepsCTA />}
            </div>
        </ErrorBoundary>
    )
})



const NextStepsCTA = observer(() => {

    var { run } = store.AutomationStore
    var { getQualifyingRule, enquiryStatuses } = store.EnquiryStore
    var { loading, automationOptions } = store.AutomationStore

    var provision = provisions.find(p => p.rule_id === getQualifyingRule)
    var form = automationOptions.find(f => f.id === provision?.form_id)

    // Available automated form
    if (provision && form && form.automated) {
        return (
            <div className="detail-section-clear">
                <p style={{marginBottom: "10px"}} >
                    This enquiry qualifies under {provision.section_name}.
                    Click the button below to automate the {provision.form_id} form.
                </p>
    
                {loading ? (
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <CircularProgress style={{width: "12px", height: "12px", margin: "0"}} />
                        <p>Automation in progress</p>
                    </div>
                ):(
                    <Button
                        variant="contained"
                        color="success"
                        className="btn btn-sm"
                        onClick={() => run(form.id)}>
                        Automate {form.id} form
                    </Button>
                )}
            </div>
        )
    }

    // Available manual form
    if (provision && form) {
        return (
            <div className="detail-section-clear">
                <p>
                    This enquiry qualifies under <i>{provision.section_name}</i>. To acquire British citizenship via this route, 
                    you must complete the <a className="link" target="_blank" href={form.link}>{form.id} form</a>.    
                </p>
            </div>
        )
    }

    // Unknown form
    return (
        <div className="detail-section-clear">
            <p>
                This enquiry has found the subject to be eligible to acquire British 
                citizenship, <a className="link" target="_blank" href="https://www.gov.uk/government/collections/citizenship-application-forms">this page</a> will 
                help you find the appropriate means to make a formal application.
            </p>
        </div>
    )
})