import React from "react"
import { history } from "../../../index"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import AccountSelect from "./AccountSelect"
import { store } from "../../../stores/Store"


export default observer(function BreadCrumb () {

    return (
        <div className={`breadcrums ${store.AppStore.sessionState ? "disabled" : ""}`}>
            
            {!store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false) ? (
                <AccountSelect value={store.NodeStore.accountUid} accounts={store.NodeStore.accounts} handleSelect={(uid) => history.push(`/app/main/${uid}`)} />
            ) : null}

            {store.NodeStore.breadcrumb.map((nodeRef, i) => (
                <React.Fragment key={i}>
                    <div className="breadcrumb-delimiter">{i > 0 ? <>/</> : null}</div>

                    <Link className="breadcrum-link" to={`/app/main/${nodeRef?.uid}`} aria-label={`Back to ${nodeRef?.name ? nodeRef?.name : nodeRef?.uid}`}>
                        {nodeRef?.name ? nodeRef?.name : nodeRef?.uid}
                    </Link>
                </React.Fragment>
            ))}
            
        </div>
    )
})


