import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"


interface CreatePersonFormProps {
    caseUid: string;
}


export default function PersonCreateForm ({caseUid}: CreatePersonFormProps) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var formik = useFormik({

        initialValues: {
            "FirstName": "",
            "FamilyName": "",
            "Sex": ""
        },

        validationSchema: Yup.object({
            FirstName: Yup.string().required("You must prvide a name"),
            FamilyName: Yup.string().required("You must prvide a family name"),
            Sex: Yup.string().required("Please select the persons Sex")
        }),

        onSubmit: async (values) => {
            
            console.log(formik)
            setError("")
            setSubmitting(true)

            return await Ajax.Node.Create("person", caseUid, values).then(() => {
            
                store.NodeStore.refreshFolderNode()
            
            }).catch((response) => {

                response.errors && formik.setErrors(response.errors)
                setError(response.data?.detail ? response.data?.detail : "Something went wrong!")
            
            }).finally(() => setSubmitting(false))

        }
    })

    return (
        <form autoComplete="off" className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>

            <div className="form-subheader">
                <h5>Basic details</h5>
            </div>

            <div className="form-fields">
                <Field.Text
                    formik
                    autoFocus
                    label="First name" 
                    name="FirstName"
                    value={formik.values.FirstName} 
                    warning={formik.submitCount ? formik.errors.FirstName : null}
                    onChange={formik.handleChange} />
                <Field.Text
                    formik
                    label="Family name" 
                    name="FamilyName"
                    value={formik.values.FamilyName} 
                    warning={formik.submitCount ? formik.errors.FamilyName : null}
                    onChange={formik.handleChange} />
                <Field.Select
                    formik
                    label="Sex" 
                    options={[{label: "Male", value: "M"}, {label: "Female", value: "F"}]} 
                    name="Sex"
                    value={formik.values.Sex} 
                    warning={formik.submitCount ? formik.errors.Sex : null}
                    onChange={formik.handleChange} />
            </div>
            
            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>

        </form>
    )
}