import React from "react"
import { reaction } from "mobx"
import { store } from "../../stores/Store"
import { useEffect, useState } from "react"
import ErrorBoundary from "../../misc/ErrorBoundary"
import useResizeObserver from "use-resize-observer";


interface PanelProps {
    index: number;
    id?: string;
    width?: string;
    children?: any | null;
    className?: string;
    hidden?: boolean;
    loadingContent?: boolean;
    minimisable?: boolean;
    minimisedDefault?: boolean;
}

export interface PanelChildProps {
    id?: string,
    index?: number;
    node?: any;
    children?: any;
    title?: string | React.ReactNode;
    hidden?: boolean;
    minimised?: any;
    minimisable?: boolean;
    className?: string;
    closeable?: boolean;
    loadingContent?: boolean;
    onClose?: () => void;
    maximise?: () => void;
}


const breakPoint = 600


export default function Panel ({
    id,
    index,
    children,
    width,
    className = "",
    hidden = false,
    loadingContent = false,
    minimisable = true,
    minimisedDefault = false
}: PanelProps) {

    const [minimised, setMinimised] = useState(minimisedDefault)
    const resizer = useResizeObserver<HTMLDivElement>();

    const mobileAutoMinimise = React.useCallback(() => {
        if (window.innerWidth < breakPoint) {
            setMinimised(id !== store.AppStore.currentPanel)
        } else {
            setMinimised(false)
        }
    }, [id])

    useEffect(() => {
        mobileAutoMinimise()
    })

    reaction(() => store.AppStore.currentPanel, currentPanel => {
        mobileAutoMinimise()
    })

    const generateClasses = () => {
        var classes = "panel ".concat(className)
        classes = hidden ? classes.concat(" panel-hidden") : classes
        classes = minimised ? classes.concat(" panel-minimised") : classes
        classes = classes.concat(` panel-width-${Math.round(resizer?.width ? resizer?.width / 100 : 0)}`)
        return classes
    }

    return (
        <div
            ref={resizer.ref}
            id={`panel-${id}`}
            className={generateClasses()}
            style={{maxWidth: window.innerWidth > breakPoint ? width : "100%"}}>
            <ErrorBoundary>
                {/* Render children and pass additional props about minimisation */}
                {React.Children.map(children, (child) => {
                    return React.cloneElement(child, {
                        id: id,
                        index: index,
                        hidden: hidden,
                        minimised: minimised,
                        minimisable: minimisable,
                        loadingContent: loadingContent,
                        maximise: () => {
                            store.AppStore.currentPanel = id
                            setMinimised(false)
                        },
                        ...child.props
                    })
                })}
            </ErrorBoundary>

        </div>
    )
}