import { Button } from "@mui/material";
import Logo from "../../../../misc/Logo";
import { Icons } from "../../../../misc/Icons";
import Panel, { PanelChildProps } from "../../../layout/Panel";


export default function PlaceholderDetail () {
    return (
        <Panel index={3} className="detail" width="100%" hidden={window.innerWidth < 700}>
            <PlaceholderDetailContent />
        </Panel>
    )
}


function PlaceholderDetailContent (props: PanelChildProps) {
    return (
        <div className="placeholder-detail">
            <div className="placeholder-detail-content">
                <Logo width={160} color="#bababa" type="block" />

                <p>Legal opinion automation</p>
                
                <div style={{display: "grid", gap: "10px", justifyItems: "left"}}>
                    {/* <a href="https://file.aoralaw.com/images/AORA SWV Getting Started.pdf" target="_blank" rel="noreferrer">
                        <Button className="btn btn-sm btn-lowercase" color="info" variant="contained"><b>Get started: Skilled worker visa</b><Icons.Open /></Button>
                    </a> */}
                    <a href="https://file.aoralaw.com/images/Aora ND Getting Started.pdf" target="_blank" rel="noreferrer">
                        <Button className="btn btn-sm btn-lowercase" color="info" variant="contained"><b>Getting started - Nationality</b><Icons.Open /></Button>
                    </a>
                    {/* <a href="https://aora-law.navattic.com/dj6y0544" target="_blank" rel="noreferrer">
                        <Button className="btn btn-sm btn-lowercase" color="info" variant="contained"><b>Interactive SWV Tutorial</b><Icons.Open /></Button>
                    </a> */}
                    <a href="https://file.aoralaw.com/images/Aora ND User Guide WEB v1.1.pdf" target="_blank" rel="noreferrer">
                        <Button className="btn btn-sm btn-lowercase" color="info" variant="contained"><b>User guide</b><Icons.Open /></Button>
                    </a>
                </div>
            </div>
        </div>
    )
}