import Logo from '../../misc/Logo'
import { useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import ParticleAnimation from '../../misc/ParticleAnimation'

export default function ErrorView () {

    const perams = useParams<any>()

    return (
        <div className="view-guest">              

            <div className="card card-sm center-h">
                <div className="card-header">
                    <h2>Something's gone wrong</h2>
                </div>

                <div className="card-body">
                    {perams.error ? perams.error : ""}
                </div>
                
                <div className="card-footer">
                    <Button 
                        className="btn btn-md" 
                        variant="contained" 
                        href="/app/main">
                        Back to App
                    </Button>
                </div>
            </div>

            <ParticleAnimation />

            <div className="view-guest-footer">       
                <Logo width="120px" color="white" />                
            </div>
        </div>       
    )
}