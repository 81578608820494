import { observer } from "mobx-react-lite"
import { history } from "../../.."
import { store } from "../../../stores/Store"
import { useEffect } from "react"
import ReportMgmtTable from "./ReportMgmtTable"
import { Button, IconButton } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import PanelHeader from "../../layout/PanelHeader"
import InlineMenu from "../../../misc/InlineMenu"
import ReportFilterForm from "./ReportFilterForm"


export default observer(function ReportMgmtTool() {
    
    const { reports, getReports } = store.ReportStore
    const { setTool } = store.ToolStore

    useEffect(() => {
        if (!store.ReportStore.reports?.length) {
            getReports()
        }
    }, [])

    return (
        <div className="panel panel-dark">
            <PanelHeader>
                <div className="header">
                    <h2>Report Management Tool</h2>
                    <InlineMenu opener={(
                        <Button className="btn btn-xs" color="info" variant="contained" style={{marginRight: "10px"}}>
                            <Icons.Filter />
                            Filter
                        </Button>
                    )} closeOnClick={false}>
                        <div style={{padding: "15px"}}>
                            <h4>Filter reports</h4>
                            <ReportFilterForm />
                        </div>
                    </InlineMenu> 
                </div>
                <IconButton style={{marginLeft: "auto"}} size="small" onClick={() => setTool(null)}><Icons.X /></IconButton>
            </PanelHeader>
            <div style={{height: "100%"}}>
                <ReportMgmtTable />
            </div>
        </div>
    )
})