import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import UserFormOnly from "./UserForms"
import { DisplayDictionary } from "../../.."
import { store } from "../../../stores/Store"
import Account from "../../../models/Account"


interface AccountEditFormProps {
    account: Account;
    onSuccess?: () => void;
}


interface AccountCreateFormProps {
    parentUid: string;
    initialValues?: any;
    onSuccess?: () => void;
}


export function AccountEditForm ({account, onSuccess = () => {}}: AccountEditFormProps) {

    const handleSubmit = async (values) => {
        return await Ajax.Node.Edit("account", values).then(() => {
            onSuccess()
            store.AlertStore.Close()
        })
    }

    if (!account) return <h4>Error loading form</h4>

    return <AccountFormOnly
        initialValues={account}
        handleCancel={() => store.AlertStore.Close()}
        handleSubmit={(values) => handleSubmit(values)} />
}


export function AccountCreateForm ({parentUid, initialValues = null, onSuccess = () => {}}: AccountCreateFormProps) {

    const [step, setStep] = React.useState<0|1>(0)
    const [newAccount, setNewAccount] = React.useState<Account>(null)

    const handleSubmit = async (userValues) => {

        newAccount.childrenObjects = [{...userValues}]

        return await Ajax.Node.Create(newAccount.accountType === "sponsor" ? "sponsor" : "account", parentUid, newAccount).then(() => {
            onSuccess()
        })
    }

    if (!parentUid) return <h4>Error loading form</h4>

    return (
        <div>
            <AccountFormOnly
                hidden={step !== 0}
                initialValues={initialValues}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={(values) => {
                    setNewAccount(values)
                    setStep(1)
                    return Promise.resolve()
                }} />

            <UserFormOnly
                hidden={step !== 1}
                includePassword={true}
                requireCredentials={false}
                guidance="Enter the details of the account contact, you can decide whether they can login either now or later."
                handleCancel={() => setStep(0)}
                handleSubmit={(values) => handleSubmit(values)} />
        </div>
    )
}


export default function AccountFormOnly ({
    handleSubmit, 
    handleCancel, 
    guidance = null,
    initialValues = null, 
    hidden = false
}) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var defaultInitialValues = {
        accountName: "",
        accountType: "",
        customerType: "",
        addressLine1: "",
        city: "",
        postcode: "",
        countryId: "",
        phone: "",
        regulator: "",
        regulatorRegistrationNumber: "",
        sponsorLicenceNum: "",
        sponsorExpiryDate: "",
    }

    // Allow some or all default values to be overridden
    if (initialValues) {
        initialValues = {...defaultInitialValues, ...initialValues}
    } else {
        initialValues = defaultInitialValues
    }

    var validationSchema = {
        accountName: Yup.string().required("This field is required"),
        customerType: Yup.string().required("This field is required"),
        addressLine1: Yup.string().required("This field is required"),
        city: Yup.string().required("This field is required"),
        postcode: Yup.string().required("This field is required"),
        countryId: Yup.string().required("This field is required"),
        phone: Yup.string().matches(
            /^\s*(?:\+?(\d{1,4}))?([-. (]*(\d{2,4})[-. )]*)?((\d{2,4})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
            "A valid phone number is required"
        ).required("This field is required"),

        sponsorLicenceNum: Yup.string().when("accountType", {is: "sponsor", 
            then: Yup.string().required("This field is required"), 
            otherwise: Yup.string().nullable()}),

        sponsorExpiryDate: Yup.string().when("accountType", {is: "sponsor", 
            then: Yup.string().required("This field is required"), 
            otherwise: Yup.string().nullable()}),

        // regulator: Yup.string().when("accountType", {is: "customer", 
        //     then: Yup.string().required("This field is required"), 
        //     otherwise: Yup.string().nullable()}),

        // regulatorRegistrationNumber: Yup.string().when("accountType", {is: "customer", 
        //     then: Yup.string().required("This field is required"), 
        //     otherwise: Yup.string().nullable()}),
    }

    var formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        onSubmit: async (values) => {

            setError("")
            setSubmitting(true)

            return await handleSubmit(values).catch((response) => {

                setError(response.data?.detail ? response.data?.detail : "Something went wrong!")
                response.errors && formik.setErrors(response.errors)

            }).finally(() => setSubmitting(false))
        }
    })

    if (hidden) return null

    return (
        <form autoComplete="off" className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>
            <div className="form-fields" style={{maxHeight: "60vh"}}>
                
                {guidance ? <small>{guidance}</small> : null}

                <Field.Text
                    formik
                    autoFocus={true}
                    label="Account name"
                    name="accountName"
                    value={formik.values.accountName}
                    warning={formik.submitCount && formik.errors.accountName ? formik.errors.accountName : false}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    label="Address"
                    name="addressLine1"
                    value={formik.values.addressLine1}
                    warning={formik.submitCount && formik.errors.addressLine1 ? formik.errors.addressLine1 : false}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    label="City"
                    name="city"
                    value={formik.values.city}
                    warning={formik.submitCount && formik.errors.city ? formik.errors.city : false}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    label="Postcode"
                    name="postcode"
                    value={formik.values.postcode}
                    warning={formik.submitCount && formik.errors.postcode ? formik.errors.postcode : false}
                    onChange={formik.handleChange} />

                <Field.MinimisedLargeListSelect
                    formik
                    multiple={false}
                    label="Country"
                    name="countryId"
                    value={formik.values.countryId}
                    warning={formik.submitCount && formik.errors.countryId ? formik.errors.countryId : false}
                    onChange={formik.handleChange}
                    options={DisplayDictionary("countries", true)} />

                <Field.Text
                    formik
                    label="Phone number"
                    name="phone"
                    value={formik.values.phone}
                    warning={formik.submitCount && formik.errors.phone ? formik.errors.phone : false}
                    onChange={formik.handleChange} />

                {/* {!formik.values.uid ? ( // Only show account type if creating a new account, uid will only be set when editing an existing account
                    <Field.Select 
                        formik 
                        name="accountType" 
                        label={"Account type "  + formik.values.accountType}
                        value={formik.values.accountType} 
                        helpText={formik.values.accountType === "sponsor" 
                            ? "A sponsor client represents an organisation which is licenced to sponsor visa applicants. This is required for skilled worker visa enquiries"
                            : "customer clients can be used for nationality enquiries but not visa enquiries."}
                        onChange={formik.handleChange}
                        warning={formik.submitCount && formik.errors.accountType ? formik.errors.accountType : false}
                        options={[
                            {value: "client", label: "Client"},
                            {value: "sponsor", label: "Sponsor client"},
                            // {value: "customer", label: "customer"}
                        ]} /> 
                ) : <span></span>} */}

                <Field.Select
                    formik
                    label="Organisation type"
                    name="customerType"
                    value={formik.values.customerType}
                    warning={formik.submitCount && formik.errors.customerType ? formik.errors.customerType : false}
                    onChange={(e) => {
                        formik.setValues({
                            ...formik.values, 
                            customerType: e.target.value, 
                            accountType: e.target.value === "I" ? "client" : formik.values.accountType
                        })
                    }}
                    options={DisplayDictionary("customer_type", true)} />

                {/* {!initialValues.uid && (formik.values.countryId === "UK" && formik.values.accountType !== "customer" && formik.values.customerType !== "I") ? <Field.Checkbox 
                    formik 
                    label="Add sponsor licence info" 
                    helpText="Required for Skilled Worker visa applications. The new account will be categorised as a 'sponsor account'." 
                    value={formik.values.accountType === "sponsor"} onChange={(e) => {
                        formik.setValues({...formik.values, accountType: e.target.checked ? "sponsor" : "client"}) 
                    }} /> 
                : null}

                {formik.values.accountType === "sponsor" ? (
                    <>
                        <Field.Text
                            formik
                            label="Sponsor licence number"
                            name="sponsorLicenceNum"
                            value={formik.values.sponsorLicenceNum}
                            warning={formik.submitCount && formik.errors.sponsorLicenceNum ? formik.errors.sponsorLicenceNum : false}
                            onChange={formik.handleChange} />
                        
                        <Field.Date
                            formik
                            label="Sponsor expiry date"
                            name="sponsorExpiryDate"
                            minDate={new Date()}
                            maxDate={new Date("2090-01-01")}
                            value={formik.values.sponsorExpiryDate}
                            warning={formik.submitCount && formik.errors.sponsorExpiryDate ? formik.errors.sponsorExpiryDate : false}
                            onChange={formik.handleChange} />      
                    </>
                ) : <span></span>} */}
                
                {/* {formik.values.accountType === "customer" ? (
                    <>
                        <Field.Select
                            formik
                            label="Regulator"
                            name="regulator"
                            value={formik.values.regulator}
                            options={DisplayDictionary("ni_regulator", true)}
                            warning={formik.submitCount && formik.errors.regulator ? formik.errors.regulator : false}
                            onChange={formik.handleChange} />

                        <Field.Text
                            formik
                            label="Regulator reference number"
                            name="regulatorRegistrationNumber"
                            value={formik.values.regulatorRegistrationNumber}
                            warning={formik.submitCount && formik.errors.regulatorRegistrationNumber ? formik.errors.regulatorRegistrationNumber : false}
                            onChange={formik.handleChange} />
                    </>
                ) : <span></span>} */}
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => handleCancel()}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>

        </form>
    )
}