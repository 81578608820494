import { Theme } from "../../../misc/Theme";

export default function DemoTheme () {
    return (
        <div className="settings-section">
            <h4>Colors</h4>

            <div className="settings-color-chart">
                <div className="color-chart-sample-group">
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.primary.dark}}><h4 style={{color: Theme.palette.primary.contrastText}}>primary - {Theme.palette.primary.dark}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.primary.main}}><h4 style={{color: Theme.palette.primary.contrastText}}>primary - {Theme.palette.primary.main}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.primary.light}}><h4 style={{color: Theme.palette.primary.contrastText}}>primary - {Theme.palette.primary.light}</h4></div>
                </div>

                <div className="color-chart-sample-group">
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.secondary.dark}}><h4 style={{color: Theme.palette.secondary.contrastText}}>secondary - {Theme.palette.secondary.dark}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.secondary.main}}><h4 style={{color: Theme.palette.secondary.contrastText}}>secondary - {Theme.palette.secondary.main}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.secondary.light}}><h4 style={{color: Theme.palette.secondary.contrastText}}>secondary - {Theme.palette.secondary.light}</h4></div>
                </div>

                <div className="color-chart-sample-group">
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.info.dark}}><h4 style={{color: Theme.palette.info.contrastText}}>info - {Theme.palette.info.dark}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.info.main}}><h4 style={{color: Theme.palette.info.contrastText}}>info - {Theme.palette.info.main}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.info.light}}><h4 style={{color: Theme.palette.info.contrastText}}>info - {Theme.palette.info.light}</h4></div>
                </div>

                <div className="color-chart-sample-group">
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.success.dark}}><h4 style={{color: Theme.palette.success.contrastText}}>success - {Theme.palette.success.dark}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.success.main}}><h4 style={{color: Theme.palette.success.contrastText}}>success - {Theme.palette.success.main}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.success.light}}><h4 style={{color: Theme.palette.success.contrastText}}>success - {Theme.palette.success.light}</h4></div>
                </div>

                <div className="color-chart-sample-group">
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.warning.dark}}><h4 style={{color: Theme.palette.warning.contrastText}}>warning - {Theme.palette.warning.dark}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.warning.main}}><h4 style={{color: Theme.palette.warning.contrastText}}>warning - {Theme.palette.warning.main}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.warning.light}}><h4 style={{color: Theme.palette.warning.contrastText}}>warning - {Theme.palette.warning.light}</h4></div>
                </div>

                <div className="color-chart-sample-group">
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.error.dark}}><h4 style={{color: Theme.palette.error.contrastText}}>error - {Theme.palette.error.dark}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.error.main}}><h4 style={{color: Theme.palette.error.contrastText}}>error - {Theme.palette.error.main}</h4></div>
                    <div className="color-chart-sample" style={{backgroundColor: Theme.palette.error.light}}><h4 style={{color: Theme.palette.error.contrastText}}>error - {Theme.palette.error.light}</h4></div>
                </div>
            </div>
        </div>
    )
}