import React from "react"
import Field from "./Field"
import Ajax from "../../../Ajax"
import { Icons } from "../../../misc/Icons"
import { SelectOption } from "./SelectField"
import { store } from "../../../stores/Store"
import { Button, CircularProgress } from "@mui/material"
import { LargeListSelectField } from "./LargeListSelectField"
import { Alert } from "../../.."


export default function ServerSideSearchField ({type, handleSubmit, returnOption = false}) {

    const [searching, setSearching] = React.useState(false)
    const [searchString, setSearchString] = React.useState("")
    const [searchResults, setSearchResults] = React.useState<SelectOption[]>([])
    const [value, setValue] = React.useState(null)
    const [error, setError] = React.useState(null)

    const search = async () => {
        
        setError("")
        setSearching(true)
        setSearchResults([])
        
        await Ajax.Node.Search(type.toLowerCase(), searchString).then((response) => {
            var resultsDicts = []
            Object.keys(response.data).forEach(key => {
                resultsDicts.push({value: key, label: response.data[key]})
            })
            setSearchResults(resultsDicts)
        }).catch(response => setError(response.data?.detail ? response.data.detail : "An error occurred while searching."))

        setSearching(false)
    }

    return (
        <form className="form" onSubmit={(e) => e.preventDefault()}>
            {/* <div className="form-header">
                <h3>{type} search</h3>
            </div> */}
            <div className="form-fields" style={{gridGap: "0px"}}>
                <div style={{display: "grid", alignItems: "end", gridTemplateColumns: "auto 34px"}}>
                    <Field.Text
                        formik
                        label="Search"
                        warning={false}
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)} />
                    
                    <Button style={{width: "34px"}} className="btn btn-sm" color="primary" variant="contained" disabled={searching} onClick={() => search()}>
                        {searching ? <CircularProgress color="primary" /> : <Icons.Search />}
                    </Button>
                </div>
                <LargeListSelectField 
                    formik
                    key={JSON.stringify(searchResults)} 
                    options={searchResults} 
                    searchable={false} 
                    value={value}
                    maxValueCount={1}
                    multiple={false}
                    onChange={(e) => setValue(e.target.value)}
                    warning={false} />

                {error ? <div className="warning">{error}</div> : null}
            </div>
            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => store.AlertStore.Close()}>Cancel</Button>
                <Button className="btn btn-sm" variant="contained" disabled={value === null} onClick={() => {

                    if (returnOption) {
                        var option = searchResults.find(o => o.value === value)
                        if (option) {
                            handleSubmit(option)
                        } else {
                            handleSubmit({value: value.toString(), label: value.toString()})
                        }
                    } else {
                        handleSubmit(value)
                    }

                    store.AlertStore.Close()

                }}>Save selection</Button>
            </div>
        </form>
    )
}