import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { Icons } from './Icons';

interface HelpProps {
    content?: string | React.ReactElement<any, any>;
    children?: any;
}

export default function Help ({
    content = "",
    children = ""
}: HelpProps) {

    const [open, setOpen] = React.useState(false)

    return (
        <Tooltip 
            open={open}
            title={children}
            onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            {content 
                ? <span style={{display: "flex", alignItems: "center"}}>{content}</span>
                : (
                    <IconButton aria-label="help" >
                        <Icons.Info color="info" style={{fontSize: "12px"}} />
                    </IconButton>
                )}
        </Tooltip>
    )
}