import {
    FormControl,
    InputLabel,
    MenuItem,
    Select as MuiSelectField
} from "@mui/material";
import { InputFieldProps } from "../../../models/Interfaces";


export interface SelectFieldProps extends InputFieldProps {
    id?: string;
    name?: string;
    value?: any;
    overrideEnter?: Function;
    options: SelectOption[];
}

export interface MulitpleSelectFieldProps extends InputFieldProps {
    value?: any[];
    options: SelectOption[];
    checkboxes?: boolean;
}

export interface SelectOption {
    value: any;
    label: string;
    name?: string;
    disabled?: boolean;
    children?: SelectOption[];
    help?: any;
}

export default function SelectField ({
    name,
    value = undefined,
    label = "",
    options,
    onChange,
    warning = "",
    helpText = "",
    formik = false,
    disabled = false,
    autoFocus = false,
    overrideEnter = () => {},
    disableUnderline = false,
    id = Math.random().toString(),
    placeholder = !label ? "Select..." : "",
}: SelectFieldProps) {

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            overrideEnter()
        }
    }

    return (
        <div className="form-field field-select" onKeyDown={handleKeyDown}>
            <FormControl>

                {label ? <InputLabel>{label}</InputLabel> : null}

                <MuiSelectField
                    name={name}
                    displayEmpty
                    disableUnderline={disableUnderline}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    value={value ? value : ""}
                    id={id}
                    // inputProps={{ onKeyDown: (e) => e.preventDefault() }}
                    onChange={(e) => {
                        // // e.preventDefault()
                        // var target: z = e.currentTarget
                        // target.parentElement
                        formik ? onChange(e) : onChange({name: name, value: e.target.value})
                    }} >

                    <MenuItem key={-1} value="" disabled>{placeholder}</MenuItem>

                    {options?.map((option, i) => (
                        <MenuItem key={i} onKeyDown={DisableEnterKey} value={option.value} disabled={disabled}>
                            {option.label}
                        </MenuItem>
                    ))}

                </MuiSelectField>

            </FormControl>

            {warning !== false ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}
        </div>
    )
}

export const MultipleSelectField = ({
    name,
    value = [],
    label = "",
    options,
    onChange,
    warning = "",
    helpText = "",
    formik = false,
    disabled = false,
    autoFocus = false,
    checkboxes = false,
    placeholder = "",
    id = Math.random().toString(),
}: MulitpleSelectFieldProps) => {

    return (
        <div className="form-field field-select">
            <FormControl>

                {label ? <InputLabel>{label}</InputLabel> : null}

                <MuiSelectField
                    id={id}
                    multiple
                    name={name}
                    displayEmpty
                    value={value}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    renderValue={(selected) => {
                        var selectedOptions = options.filter(o => selected.includes(o.value))
                        
                        if (selectedOptions.length) {
                            return selectedOptions?.map(o => o.label).join(", ")
                        }
                        if (placeholder) {
                            return <span className="field-placeholder">{placeholder}</span>
                        }
                    }}
                    onChange={(e) => {
                        formik ? onChange(e) : onChange({name: name, value: e.target.value})
                    }} >

                    {options?.map((option, i) => (
                        <MenuItem key={i} onKeyDown={DisableEnterKey} value={option.value} disabled={disabled} style={{paddingLeft: "6px"}}>
                            {checkboxes 
                                ? <input type="checkbox" checked={value.includes(option.value)} style={{marginRight: "6px"}} />
                                : null}
                            {option.label}
                        </MenuItem>
                    ))}

                </MuiSelectField>

            </FormControl>

            {warning !== false ? <span className="warning">{warning}</span> : null}

            {helpText ? <span className="help-text">{helpText}</span> : null}
        </div>
    )
}


const DisableEnterKey = (e) => {
    // if (e.key === "Enter") {
    //     e.preventDefault()
    //     return false
    // }
}