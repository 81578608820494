import Ajax from '../Ajax'
import { makeAutoObservable, reaction } from 'mobx'
import { Report } from '../models/Report'
import { store } from './Store';
import { Alert } from '..';

export class ReportStore {

    reports: Report[] = [];
    searchString: string = "";
    orderedBy: string = "requestedAt";
    order: string = "desc";
    loading: boolean = true;

    constructor () {
        
        makeAutoObservable(this)
    }

    get enquiryReports () {
        var reports = []

        if (store.EnquiryStore.enquiry) {
            var rSection = store.EnquiryStore.enquiry?.childrenObjects?.find(section => section.name === "REPORTS")
            if (rSection) {
                reports = rSection.childrenObjects
            }
        }

        // Order by date
        // reports = [...reports].sort((a, b) => {
        //     return new Date(a.generatedAt).getTime() - new Date(b.generatedAt).getTime()
        // })

        // Order by id and version
        reports = [...reports].sort((a, b) => a.uid?.localeCompare(b.uid))

        return reports
    }

    get enquiryReportsCurrentOnly () {

        var reports = [...this.enquiryReports]

        reports = reports.filter(report => {
            var newerVersion = reports.find(r => {
                return (
                    (r.statusId === report.statusId && r.reportType === report.reportType) && // Duplicate
                    (r.reportLanguageId > report.reportLanguageId || (r.reportLanguageId === report.reportLanguageId && r.version > report.version)) // True if version numbers are higher
                )
            })
            return !newerVersion
        })
        
        return reports
    }

    checkForPendingReports = () => {
        var pendingReport = this.enquiryReports?.find(r => r.fileStatus === "R")
        
        // Reload if report is in process of regenerating
        if (pendingReport) {
            return setTimeout(() => this.refreshReports(), 5000)
        }
    }


    filterReports = (reports: Report[]) => {

        if (!Array.isArray(reports) || !reports.length){
            return []
        }

        reports = [...reports].sort((a, b) => {

            if (typeof reports[0][this.orderedBy] === "number") {
                return this.order === "desc"
                    ? b[this.orderedBy] - a[this.orderedBy]
                    : a[this.orderedBy] - b[this.orderedBy]
            }

            if (typeof reports[0][this.orderedBy] === "string") {
                return this.order === "desc"
                    ? b[this.orderedBy].localeCompare(a[this.orderedBy])
                    : a[this.orderedBy].localeCompare(b[this.orderedBy])
            }

            if (typeof reports[0][this.orderedBy] === "object") {
                return this.order === "desc"
                    ? b[this.orderedBy].name.localeCompare(a[this.orderedBy].name)
                    : a[this.orderedBy].name.localeCompare(b[this.orderedBy].name)
            }

            return false
        })

        if (this.searchString) {
            reports = reports.filter(r => {
                return (
                    r.case?.name?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    r.subject?.name?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    r.requestedBy?.name?.toLowerCase().includes(this.searchString.toLowerCase())
                )
            })
        }

        return reports
    }

    groupByNationality = (reports) => {

        var reportsByNationality = {}

        reports.forEach(report => {
            if (report.statusId in reportsByNationality) {
                reportsByNationality[report.statusId].push(report)
            } else {
                reportsByNationality[report.statusId] = [report]
            }
        })

        return reportsByNationality
    }

    refreshReports = async () => {
        if (store.EnquiryStore.enquiry) {
            await Ajax.Node.Get(store.EnquiryStore.enquiry.uid).then((response) => {
                store.NodeStore.setLeafNode(response.data)
            })
        } 
        if (this.reports.length) {
            await Ajax.Report.All().then((response) => {
                this.reports = response.data
            })
        }
        
    }

    getReports = async () => {
        
        this.loading = true
        this.setReports([])

        await Ajax.Report.All().then((response) => {
            this.reports = response.data
        }).finally(() => {
            this.loading = false
        })
    }

    setReports = (reports: any[]) => {
        this.reports = reports
    }

    makeInvalid = async (reportNode) => {

        await Ajax.Report.MarkInvalid(reportNode)
            .catch(response => Alert({message: response?.data?.detail}))

        this.refreshReports()
    }

    regenerate = async (reportNode) => {

        await Ajax.Report.Regenerate(reportNode)
            .then(() => store.NodeStore.refreshLeafNode())
            .catch(response => Alert({message: response?.data?.detail}))

        this.refreshReports()
    }

    download = async (reportNode) => {
        await Ajax.Report.File(reportNode, `${reportNode.label}.docx`)
            .catch((response) => Alert({message: response?.data?.detail}))
    }
}