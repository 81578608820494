import * as Yup from 'yup'
import { useFormik } from "formik"
import Field from "../fields/Field"
import { DisplayValue } from '../../..'
import { FormSectionProps } from '../../../models/Interfaces'
import { store } from '../../../stores/Store'


export default function EnquiryStatusesFormSection ({formId, submit, section}: FormSectionProps) {

    var formik = useFormik({

        initialValues: { statuses: [] },

        validationSchema: Yup.object({
            statuses: Yup.array()
                .min(1, `You must select a nationality`)
        }),

        onSubmit: (values) => {
            var newSection = {...section}
            newSection.childrenObjects = section.childrenObjects
            // .filter(status => status.code.slice(-2) !== "_P") // Don't send potential statuses, these can only be set by the backend
            .map(status => {
                status.selected = values.statuses.includes(status.uid)
                return status
            })
            submit(newSection)
        }
    })

    // Transform flat list of statuses into a tree structured by type
    var nationalityOptions = [{ value: "", label: "All", children: [] }]

    // Add each status to tree
    section.childrenObjects.forEach(nat => {

        var existingType = false
        var nationality = { 
            value: nat.uid, 
            label: nat.label, 
            // disabled: nat.code?.slice(-2) === "_P" // Potential statuses may only be set by the backend
        }

        // Add to tree under existing parent
        nationalityOptions[0].children.forEach(type => {
            if (nat.type === type.value) {
                existingType = true
                type.children.push(nationality)
            }
        })
        
        // Add to tree under new parent
        if (!existingType) {
            nationalityOptions[0].children.push({
                label: DisplayValue("nationality_type", nat.type),
                value: nat.type,
                children: [nationality]
            })
        }
        
        // Set default initial values
        nat.selected && formik.initialValues.statuses.push(nationality.value)
    })

    return (
        <>
            <p style={{margin: "10px 0 0 0"}}>Select the statuses you want to evaluate</p>

            <form id={formId} className="form-fields" style={{maxHeight: "300px", overflow: "auto"}} onSubmit={formik.handleSubmit}>
                <Field.CheckboxSelect
                    formik
                    name={"statuses"}
                    options={nationalityOptions}
                    onChange={formik.handleChange}
                    value={formik.values.statuses}
                    warning={null}
                />
            </form>

            {formik.errors.statuses
                ? <div className="form-message warning">{formik.errors.statuses}</div>
                : null}
        </>
    )
}



