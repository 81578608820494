import React from "react"
import { AlertModal } from "../../.."
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { Icons, TypeIcon } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import InlineMenu from "../../../misc/InlineMenu"
import { AccountCreateForm } from "../../form/admin/AccountForms"
import { Button, CircularProgress, MenuItem, MenuList } from "@mui/material"
import TruncText from "../../../misc/TruncText"
import Account from "../../../models/Account"


interface AccountSelectProps {
    value: string;
    accounts: Account[];
    handleSelect: Function;
    color?: "primary" | "secondary" | "info";
}


export default observer(function AccountSelect ({value, accounts, handleSelect, color = "primary"} : AccountSelectProps) {

    const currentAccount = accounts.find(account => account.uid === value)
    const buttonColor = value === store.AppStore.sessionInfo.parentAccount.uid 
        ? color 
        : currentAccount ? "secondary" : "info"

    if (!accounts.length) return <CircularProgress color="info" style={{width: "10px", height: "10px"}} />

    return (
        <div className="account-select">
            <InlineMenu closeOnClick={true} title="Accounts" opener={(
                <Button 
                    className="btn btn-xs" 
                    variant="contained" 
                    color={buttonColor}>
                    <Icons.ArrowRight style={{transform: "rotateZ(90deg)", fontSize: "20px"}} />
                </Button>
            )}>
                <AccountSelectMenu value={value} accounts={accounts} handleSelect={handleSelect} />
            </InlineMenu>
            
            <Button 
                className="btn btn-xs btn-lowercase" 
                variant="contained" 
                color={buttonColor} 
                onClick={() => handleSelect(currentAccount?.uid)}>
                {currentAccount ? currentAccount?.label : <CircularProgress color="info" style={{width: "10px", height: "10px"}} />}
            </Button>
        </div>
    )
})


function AccountSelectMenu ({value, accounts, handleSelect}) {

    const account = accounts.find(account => account.uid === value)
    var mainAccount = store.NodeStore.accounts.find(account => account.uid === store.AppStore.sessionInfo?.parentAccount?.uid)
    const inOtherAccount = value !== mainAccount?.uid

    return (
        <div className="account-select-menu">
            <Button onClick={() => handleSelect(mainAccount?.uid)} className="btn btn-lg btn-lowercase">
                <h4 style={{textDecoration: "underline"}}>
                    {mainAccount?.label} {inOtherAccount && <Icons.Open />}
                </h4>
            </Button>
            
            {!inOtherAccount ? <small>You are currently working within your account</small> : (
                <>
                    <small>You are currently working within <b>{account?.label}'s</b> account.</small>
                    <small className="link"><Link to={"/app/main"}>Back to your account</Link></small>
                </>
            )}
            
            <AccountsList value={value} accounts={accounts} handleSelect={handleSelect} />

            <div className="button-group">
                <Button 
                    className="btn btn-sm" 
                    variant="contained" 
                    onClick={(e) => AlertModal({title: "Create new account", body: <AccountCreateForm parentUid={store.AppStore.sessionInfo?.parentAccount?.uid} onSuccess={() => {
                        store.NodeStore.refreshAccounts()
                        store.AlertStore.Close()
                    }} />})}>
                    Create new account
                </Button>
                {store.AppStore.checkJwtRole("admin_account") ? (
                    <Button 
                        className="btn btn-sm" 
                        variant="contained" 
                        onClick={(e) => AlertModal({title: "Create new customer", body: <AccountCreateForm parentUid="ACCOUNT_-1" initialValues={{accountType: "customer"}} onSuccess={() => {
                            store.NodeStore.refreshAccounts()
                            store.AlertStore.Close()
                        }} />})}>
                        Create new customer
                    </Button>
                ) : null}
            </div>
        </div>
    )
}


function AccountsList ({value, accounts, handleSelect}) {

    var customers = accounts.filter(account => account.typeName === "Aora.Platform.Data.AccountNode" && account.accountType === "customer")
    var clients = accounts.filter(account => account.typeName === "Aora.Platform.Data.AccountNode" && account.accountType === "client")
    var sponsorClients = accounts.filter(account => account.typeName === "Aora.Nationality.Data.SponsorAccountNode")

    var clientLabel = store.NodeStore.getDisplayType({typeName: "Aora.Platform.Data.AccountNode"}, true)
    var sponsorLabel = store.NodeStore.getDisplayType({typeName: "Aora.Nationality.Data.SponsorAccountNode"}, true)

    return (
        <div className="account-list">
            {store.AppStore.checkJwtRole("admin_account") ? 
                <AccountListGroup groupHeader="Customers" accounts={customers} value={value} handleSelect={handleSelect} /> : null}
            
            <AccountListGroup groupHeader={clientLabel} accounts={clients} value={value} handleSelect={handleSelect} />
            
            <AccountListGroup groupHeader={sponsorLabel} accounts={sponsorClients} value={value} handleSelect={handleSelect} />
        </div>
    )
}


function AccountListGroup ({groupHeader, value, accounts, handleSelect}) {

    const [minimised, setMinimised] = React.useState(false)

    return (
        <>
            <div className="menu-divider">
                <Button className="btn btn-sm btn-lowercase" onClick={(e) => {
                    e.stopPropagation()
                    setMinimised(!minimised)
                }}>
                    <b style={{color: "black"}}>{groupHeader}</b>
                    <Icons.ArrowRight style={{fontSize: "16px", padding: "0", transform: minimised ? "rotate(0)" : "rotate(90deg)"}} />
                </Button>
            </div>
            
            {!minimised ? (
                <MenuList>
                    {accounts.map((account, i) => (
                        <MenuItem key={i} selected={account.uid === value} onClick={() => handleSelect(account.uid)}>
                            <TypeIcon node={account} />
                            <TruncText text={account.label} />
                        </MenuItem>
                    ))}
                </MenuList>
            ) : null}

            <div style={{padding: "3px"}}></div>
        </>
    )
}