import React from 'react';
import Ajax from '../../../Ajax';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons';
import { Alert, AlertModal } from '../../..';
import { store } from '../../../stores/Store';
import {SystemInformation} from '../../modals/SystemInformation';
import PersonCreateForm from '../../form/entity/PersonCreateForm';
import InformationRequestForm from '../../form/InfomationRequestForm';
import Panel from '../../layout/Panel';
import PanelLabel from '../../layout/PanelLabel';
import PanelHeader from '../../layout/PanelHeader';
import PanelBody from '../../layout/PanelBody';


export default observer(function QuestionPanel () {

    const {AppStore, QuestionStore, NodeStore} = store
    const [cancelling, setCancelling] = React.useState(false)

    var stateLowerCase = AppStore.sessionState?.toLowerCase()
    var title = stateLowerCase
        ? `${AppStore.stateAction} ${AppStore.stateSubject}`.split("_")[0].toLowerCase().replace("node", "") // .replaceAll("_", " ").toLowerCase().replace("node", "")
        : QuestionStore.initialisationForm?.toLowerCase().replace("_", " ").replace("node", "")

    const hidden = !QuestionStore.showQuestionPanel

    const cancel = async () => {
        setCancelling(true)
        if (!QuestionStore.informationRequest && QuestionStore.initialisationForm) {
            QuestionStore.initForm(null)
        } else {
            await Ajax.Message.Cancel().then(() => {
                QuestionStore.informationRequest = null
            }).catch(() => Alert({message: "Failed to cancel"}))
        }
        setCancelling(false)
    }

    return (
        <Panel
            id="QUESTION"
            index={1}
            minimisable
            className="panel-question"
            hidden={hidden}
            loadingContent={hidden}
            width={hidden ? "0px" : "270px"} >

            <PanelLabel title="Information request" />

            <PanelHeader>
                <div style={{display: "grid", gap: "2px"}}>
                    <h4 className="trunc-ellipsis" style={{textTransform: "capitalize"}}>{title}</h4>
                    <small className="panel-header-node-type"><Icons.Info />Information request</small>
                </div>

                <Button
                    color="info"
                    className="btn btn-sm"
                    aria-label="Pause enquiry"
                    disabled={cancelling}
                    onClick={() => cancel()}>
                    {stateLowerCase?.includes("running") ? <Icons.Pause /> : <Icons.X />}
                </Button>
            </PanelHeader>

            <PanelBody>

                {!stateLowerCase && QuestionStore.initialisationForm === "CREATE_PERSON" ?
                    <PersonCreateForm caseUid={NodeStore.folderNode.uid} /> : null}

                {stateLowerCase ? <InformationRequestForm /> : null}

                {store.ConnectionStore.connectionState !== "Connected" ? (
                    <small className="link" style={{marginTop: "15px"}} onClick={() => AlertModal({body: <SystemInformation />, size: "lg"})}>
                        You have a faulty connection and may not receive questions
                    </small>
                ) : null}
            </PanelBody>

        </Panel>
    )
})